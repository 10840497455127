import React, { useEffect, useRef, useState } from "react";
import { match } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";

import IRouterComponentProps from "../../../models/IRouterComponentProps";
import AuthService from "../../auth/AuthService";
import {
  auctionProductUrl,
  productUrl,
  productTextSearch,
  auctionProductMyBidsUrl,
  preBiddingEndTime,
} from "../../../common/config/app.endpoints";
import AppLayout from "../../layouts/AppLayout";
import ProductListView from "./ProductListView";
import Alert from "../../../common/elements/Alert";
import LoadingSpinner from "../../../common/elements/LoadingSpinner";
import moment from "moment";
import ApiTimeout from "../../../modules/ApiTimeout";
import { oAuth } from "../../../routes/basic/BasicAppRouter";
import { StyledModal } from "../../../common/materials/GenericStyleComponent";
import ProductFilter from "./ProductsFilter";
import BlockUserModal from "../BlockUser/BlockUserModal";
import { buttonGradient } from "../../../common/materials/LinearGradient";
import TabLayout from "./TabLayout";
import ParcelItemFilter from "./ParcelFilter";
import { useMediaQuery, useTheme } from "@material-ui/core";
import TableContainer from "./liveAuctionProductListview/TableContainer";
import { useDispatch, useSelector } from "react-redux";
import {
  resetLiveIsGridView,
  selectLiveGridView,
  setLiveIsGridView,
} from "../../../redux/slice/annual/commanAnnualSlice";
import { AppDispatch } from "../../../redux/store";
import { RootState } from "../../../redux/reducer";
import {
  reSetLiveCurrentApiStartCount,
  selecLiveCurrentApiStartCount,
  selectLiveItemPerPage,
  setLiveAuctionPagination,
  setLiveCurrentApiStartCount,
} from "../../../redux/slice/commanPagination/commanPaginationSlice";
import CoreDataGridofProductLive from "../../core-grid/grid-data/CoreDataGridofProductLive";
import BulkWatchlistPopup from "./bulkWatchlistComponent/BulkWatchlistPopup";

const StyledTextWrapper = styled(Grid)`
  margin: auto;
  text-align: center;
`;

const StyledLayoutGrid = styled(Grid)`
  padding: 20px 20px;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    padding: 10px 10px;
  }
`;

const StyledBoxContent = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 2px;
  border-top: 4px solid #000f7e;
`;

export const StyledButtonSearch = styled.button`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 1em;
  padding: 0.6em;
  border-radius: 5px;
  margin-left: 5px;
  margin-top: 5px;
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  // background: ${buttonGradient};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
`;

const StyledDropDown = styled.img`
  width: 2em;
  height: 2em;
  cursor: pointer;
`;

interface DetailParams {
  auctionId: string;
  auctionName: string;
  auctionDate: string;
}

export interface IProductViewProps extends IRouterComponentProps {
  match: match<DetailParams>;
  t?: any;
  location: any;
}

let filter: any = [];
let filteredValues: any = "";
let filteredParcelValues: any = "";
let body: any = [];
let data: any = {};
let sourceFromUrl: any;

let itemPerPageForTable: any;
let auctioneerSellerUserId: any = "";
let prebidEndTime: any;

let isReadOnlyUser: any;
const ProductView = (props: IProductViewProps) => {
  const [auctionProducts, setAuctionProducts] = useState([]);
  const [openToast, setOpenToast] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [totalElements, setTotalElements] = useState(0);
  const [apiTimeout, setApiTimeout] = useState(false);
  const [onAction, setOnAction] = useState(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [userBlock, setUserBlock] = useState(false);
  const [isShowSearchBox, setShowSearchBox] = useState(true);
  const [isShowParcelItem, setShowParcelItem] = useState(false);
  const [optionValue, setOptionValue] = useState("");
  const [textSearchArray, setTextSearchArray] = useState([]);
  const [isSort, setIsSort] = useState<boolean>(false);
  const [filters, setFilters] = useState();
  const [exportIds, setexportIds] = useState<any[]>([]);
  //List Ui changes
  const [uiBoxView, setUiBoxView] = useState(false);
  const [openBulkWatchModal, setOpenBulkWatchModal] = useState<boolean>(false);

  const Auth = new AuthService();
  const { t } = props;
  let date: any = "";
  let timezone: any = "";
  let startCount: number = 0;
  let endCount: number = 20;
  let tabSelectedVal: number = 1;
  let WatchlistParcelType: any;
  let currentPage = useRef(0);
  const dispatch: AppDispatch = useDispatch();
  const isGridView: any = useSelector((state: RootState) =>
    selectLiveGridView(state)
  );

  useEffect(() => {
    setIsLoading(true);
    localStorage.removeItem("auctionUserId");
    localStorage.removeItem("userId");
    localStorage.removeItem("currentStart");
    localStorage.setItem("urlRedirectFromAdmin", window.location.href);
    if (localStorage.getItem("tabSelectedVal") === "2") {
      setShowParcelItem(true);
    }
    
    const urlParams = new URLSearchParams(window.location.search);
    sourceFromUrl = urlParams.get("source");
    var joinedAuctions: any = localStorage.getItem("joinedAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);
    prebidEndTimeCall();
    if (joinedAuctions !== null) {
      joinedAuctions.filter((items: any) => {
        if (items.perId === props.match.params.auctionId) {
          auctioneerSellerUserId = items.auctionUserId;
        }
      });
    }

    isReadOnlyUser = localStorage.getItem("isReadOnlyUser");
    isReadOnlyUser = JSON.parse(isReadOnlyUser);

    return () => {
      setIsLoading(false);
      setAuctionProducts([]);
    };
  }, []);

  const prebidEndTimeCall = async () => {
    await Auth.getRequest(
      `${preBiddingEndTime}/${props.match.params.auctionId}`,
      (status: number, response: any) => {
        if (status === 200) {
          prebidEndTime = response.previewEndDate;
        }
      }
    );
  };

  //redux page selector
  const selectcurrentApiStartCountValue: any = useSelector((state: RootState) =>
    selecLiveCurrentApiStartCount(state)
  );

  const selectItemPerPageValue: any = useSelector((state: RootState) =>
    selectLiveItemPerPage(state)
  );

  useEffect(() => {
    getAuctionProducts(props.match.params.auctionId);
  }, [selectItemPerPageValue, selectcurrentApiStartCountValue]);

  const tabItemClick = (value: String) => {
    if (value === "2") {
      tabSelectedVal = 2;
      localStorage.setItem("tabSelectedVal", "2");
      setShowParcelItem(true);
    } else {
      tabSelectedVal = 1;
      localStorage.setItem("tabSelectedVal", "1");
      setShowParcelItem(false);
    }
    getAuctionProducts(props.match.params.auctionId);
  };

  const getAuctionProducts = (auctionId: number | string) => {
    setOpenToast(false);
    body = [];
    filteredValues = "";
    filteredParcelValues = "";

    if (localStorage.getItem("tabSelectedVal") === "1") {
      filteredValues = localStorage.getItem("filteredValues");
      filteredValues = JSON.parse(filteredValues);

      if (filteredValues !== undefined) {
        if (filteredValues === null || filteredValues.length === 0) {
          filter = null;
        } else {
          filteredValues.map((item: any, index: number) => {
            if (item.name === "auctionId") {
              filteredValues.splice(index, 1);
            }
          });
          filter = filteredValues;
        }
      }
    } else {
      filteredParcelValues = localStorage.getItem("filteredParcelValues");
      filteredParcelValues = JSON.parse(filteredParcelValues);

      if (filteredParcelValues !== undefined) {
        if (
          filteredParcelValues === null ||
          filteredParcelValues.length === 0
        ) {
          filter = null;
        } else {
          filteredParcelValues.map((item: any, index: number) => {
            if (item.name === "auctionId") {
              filteredParcelValues.splice(index, 1);
            }
          });
          filter = filteredParcelValues;
        }
      }
    }

    getBody(auctionId);
  };

  const getBody = (auctionId: string | number) => {
    data = {};
    if (localStorage.getItem("tabSelectedVal") === "1") {
      filteredValues = localStorage.getItem("filteredValues");
      filteredValues = JSON.parse(filteredValues);
    } else {
      filteredParcelValues = localStorage.getItem("filteredParcelValues");
      filteredParcelValues = JSON.parse(filteredParcelValues);
    }

    if (
      localStorage.getItem("currentStart") !== null &&
      localStorage.getItem("currentStart") !== "0"
    ) {
      let savedCount: any = localStorage.getItem("currentStart");
      startCount = parseInt(savedCount);
    }
    if (localStorage.getItem("itemsPerPage") !== null) {
      let savedCount: any = localStorage.getItem("itemsPerPage");
      endCount = parseInt(savedCount);
    }
    if (localStorage.getItem("currentPdPage") !== null) {
      let pageCount: any = localStorage.getItem("currentPdPage");
      // currentPage.current = pageCount;
      let start = (parseInt(pageCount) - 1) * endCount;
      startCount = parseInt(start.toString());
      localStorage.removeItem("currentPdPage");
    }

    //handle drop downfilter value

    if (
      localStorage.getItem("dropDownFilterVal") !== undefined &&
      localStorage.getItem("dropDownFilterVal") !== null
    ) {
      var tempArr = localStorage.getItem("dropDownFilterVal");
      if (tempArr !== null) var dropDownValues = JSON.parse(tempArr);
      var property = dropDownValues[0].property;
      var direction = dropDownValues[0].direction;
    }

    if (
      (filter === null &&
        (filteredValues == null ||
          filteredValues === undefined ||
          filteredValues === "null" ||
          filteredValues.length === 0)) ||
      (filter === null &&
        (filteredParcelValues == null ||
          filteredParcelValues === undefined ||
          filteredParcelValues === "null" ||
          filteredParcelValues.length === 0))
    ) {
      data = {
        filters: {
          and: [
            {
              match: "exact",
              name: "auctionId",
              value: auctionId,
            },
          ],
        },
        page: {
          start: selectcurrentApiStartCountValue * selectItemPerPageValue,
          count: parseInt(selectItemPerPageValue),
          sort: {
            orders:
              property === "serialNo"
                ? [
                    {
                      property:
                        property !== null && property !== undefined
                          ? property
                          : "serialNo",
                      direction:
                        direction !== null && direction !== undefined
                          ? direction
                          : "ASC",
                    },
                  ]
                : [
                    {
                      property:
                        property !== null && property !== undefined
                          ? property
                          : "serialNo",
                      direction:
                        direction !== null && direction !== undefined
                          ? direction
                          : "ASC",
                    },
                    {
                      property: "serialNo",
                      direction:
                        direction !== null && direction !== undefined
                          ? direction
                          : "ASC",
                    },
                  ],
          },
        },
      };
    } else {
      if (filteredValues !== null && filteredValues.length > 0) {
        body = filteredValues ? filteredValues : filter;
      } else {
        body = filteredParcelValues ? filteredParcelValues : filter;
      }

      body.map((item: any, index: number) => {
        if (item.name === "auctionId") {
          body.splice(index, 1);
        }
      });

      if (localStorage.getItem("tabSelectedVal") === "1") {
        // body.push({
        //   match: "exact",
        //   name: "auctionId",
        //   value: props.match.params.auctionId,
        // });
        data = {
          filters: {
            or: body,
            and: [
              {
                match: "exact",
                name: "auctionId",
                value: props.match.params.auctionId,
              },
              {
                match: "exact",
                name: "parcelType",
                value: "Diamond",
              },
            ],
          },
          page: {
            start: selectcurrentApiStartCountValue * selectItemPerPageValue,
            count: parseInt(selectItemPerPageValue),
            sort: {
              orders:
                property === "serialNo"
                  ? [
                      {
                        property:
                          property !== null && property !== undefined
                            ? property
                            : "serialNo",
                        direction:
                          direction !== null && direction !== undefined
                            ? direction
                            : "ASC",
                      },
                    ]
                  : [
                      {
                        property:
                          property !== null && property !== undefined
                            ? property
                            : "serialNo",
                        direction:
                          direction !== null && direction !== undefined
                            ? direction
                            : "ASC",
                      },
                      {
                        property: "serialNo",
                        direction:
                          direction !== null && direction !== undefined
                            ? direction
                            : "ASC",
                      },
                    ],
            },
          },
        };
      } else {
        let andArr = [];
        andArr.push(
          {
            match: "exact",
            name: "auctionId",
            value: props.match.params.auctionId,
          },
          {
            match: "exact",
            name: "parcelType",
            value: "Parcel",
          }
        );
        data = {
          filters: {
            or: body,
            and: andArr,
          },
          page: {
            start: selectcurrentApiStartCountValue * selectItemPerPageValue,
            count: parseInt(selectItemPerPageValue),
            sort: {
              orders:
                property === "serialNo"
                  ? [
                      {
                        property:
                          property !== null && property !== undefined
                            ? property
                            : "serialNo",
                        direction:
                          direction !== null && direction !== undefined
                            ? direction
                            : "ASC",
                      },
                    ]
                  : [
                      {
                        property:
                          property !== null && property !== undefined
                            ? property
                            : "serialNo",
                        direction:
                          direction !== null && direction !== undefined
                            ? direction
                            : "ASC",
                      },
                      {
                        property: "serialNo",
                        direction:
                          direction !== null && direction !== undefined
                            ? direction
                            : "ASC",
                      },
                    ],
            },
          },
        };
      }
    }
    var pattern = /^[0-9,]*$/g;
    let val = localStorage.getItem("optionValue");
    if (val !== "" && val !== "null" && val != null) {
      setOptionValue(val);
      let filterOR: any;
      let searchValue: any = val.replace(/ /g, "");
      if (pattern.test(searchValue)) {
        const myArray = searchValue.split(",");
        var filtered = myArray.filter(function (el: string) {
          return el != null && el !== "";
        });
        var convertedArr = filtered.map(function (item: string) {
          return parseInt(item, 10);
        });
        filterOR = [
          {
            match: "in",
            name: "serialNo",
            value: convertedArr,
          },
        ];
      } else {
        filterOR = [
          {
            match: "anywhere",
            name: "productDescription",
            value: val,
          },
          {
            match: "anywhere",
            name: "remarks",
            value: val,
          },
        ];
      }
      data = {
        filters: {
          or: filterOR,
          and: [
            {
              match: "exact",
              name: "auctionId",
              value: props.match.params.auctionId,
            },
          ],
        },
        page: {
          start: selectcurrentApiStartCountValue * selectItemPerPageValue,
          count: parseInt(selectItemPerPageValue),
          sort: {
            orders: [
              {
                property: "serialNo",
                direction: "ASC",
              },
            ],
          },
        },
      };
    }
    callApi(data);
  };

  const itemPerPage = (value: boolean, start: number, itemPerPage: number) => {
    if (value === true) {
      localStorage.setItem("itemsPerPage", JSON.stringify(itemPerPage));
      getUpdatedProducts(value, start, itemPerPage);
      itemPerPageForTable = itemPerPage;
    }
  };

  const getUpdatedProducts = (value: any, start: any, endRecords: any) => {
    setexportIds([]);
    if (value === true) {
      getProducts(start, endRecords);
    }
  };

  const getProducts = (start: number, itemsPerPage: any) => {
    setexportIds([]);
    setIsLoading(true);
    startCount = start;
    endCount = itemsPerPage;

    localStorage.setItem("currentStart", JSON.stringify(start));
    //   getBody(props.match.params.auctionId);
    let currentPage = start / itemsPerPage;
    dispatch(setLiveCurrentApiStartCount({ currentPage }));
    dispatch(
      setLiveAuctionPagination({ itemsPerPage: parseInt(itemsPerPage) })
    );
  };

  const callApi = (data: any) => {
    setOnAction(true);
    if (!oAuth()) {
      props.history.push("/");
    } else {
      let sourceAuctionProduct =
        sourceFromUrl === "admin"
          ? `${auctionProductUrl}?auctionId=${props.match.params.auctionId}&redirectFromPreview=true`
          : `${auctionProductUrl}?auctionId=${props.match.params.auctionId}`;
      Auth.postRequest(
        sourceAuctionProduct,
        data,
        (status: any, response: any) => {
          if (status === 200) {
            if (response !== undefined) {
              localStorage.setItem(
                "currentStart",
                JSON.stringify(data.page.start)
              );
              setTotalElements(response.totalElements);
              setAuctionProducts(response.results);
              setOnAction(false);
              setIsLoading(false);
              setAuctionProducts(response.results);
              if (
                localStorage.getItem("currentPDItem") !== null &&
                localStorage.getItem("currentPDItem") !== undefined
              ) {
                const id: any = localStorage.getItem("currentPDItem") || "";
                var element: any = document.getElementById(id);
                if (element !== undefined && element !== null) {
                  element.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "nearest",
                  });
                }
                localStorage.removeItem("currentPDItem");
              }
            }
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 505) {
            setApiTimeout(true);
            setOnAction(false);
          } else if (status === 404) {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(`${t("common:invalidRequest")}`);
            setAlertType("error");
            setOpenToast(true);
          } else {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(response[0].message);
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };

  const addToWatchList = (
    productId: string | number,
    auctionId: string | number,
    serialNo: string | number
  ) => {
    setOnAction(true);
    setOpenToast(false);
    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.postRequest(
        `${productUrl}/${auctionId}/${productId}/watch`,
        "data",
        (status: any, response: any) => {
          if (response !== undefined) {
            if (status === 200) {
              // callApi(data);
              const newArr: any = auctionProducts.map((obj: any) => {
                if (obj.perId === productId) {
                  return {
                    ...obj,
                    watched: true,
                  };
                }
                return obj;
              });
              setAuctionProducts(newArr);
              setIsLoading(false);
              setOnAction(false);
              setShowAlertMessage(`${t("common:preview:addedToWatchMsg")}`);
              setAlertType("success");
              setOpenToast(true);
            } else if (status === 403) {
              setUserBlock(true);
            } else if (status === 404) {
              setIsLoading(false);
              setShowAlertMessage(`${t("common:invalidRequest")}`);
              setOnAction(false);
              setAlertType("error");
              setOpenToast(true);
            } else if (status === 505) {
              setApiTimeout(true);
              setOnAction(false);
            } else {
              setIsLoading(false);
              setShowAlertMessage(response[0].message);
              setOnAction(false);
              setAlertType("error");
              setOpenToast(true);
            }
          } else {
            setIsLoading(false);
            setShowAlertMessage(response);
            setOnAction(false);
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };

  const removeFromWatchlist = (
    productId: string | number,
    auctionId: string | number
  ) => {
    setOnAction(true);
    setOpenToast(false);
    if (Auth.loggedIn() === null) {
      props.history.push("/");
    } else {
      Auth.postRequest(
        `${productUrl}/${auctionId}/${productId}/unwatch`,
        "data",
        (status: any, response: any) => {
          if (status === 200) {
            const newArr: any = auctionProducts.map((obj: any) => {
              if (obj.perId === productId) {
                return {
                  ...obj,
                  watched: false,
                };
              }
              return obj;
            });
            setAuctionProducts(newArr);
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(response.data.message);
            setAlertType("success");
            setOpenToast(true);
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 404) {
            setIsLoading(false);
            setShowAlertMessage(`${t("common:invalidRequest")}`);
            setOnAction(false);
            setAlertType("error");
            setOpenToast(true);
          } else if (status === 505) {
            setApiTimeout(true);
            setOnAction(false);
          } else {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(response[0].message);
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };

  const handleFilters = () => {
    setIsFiltersOpen(!isFiltersOpen);
    setShowSearchBox(!isShowSearchBox);
  };

  const filteredAuction = async (
    auctions: any,
    isFilter: boolean,
    values: any,
    isCustomCoode?: any
  ) => {
    localStorage.removeItem("currentStart");
    if (localStorage.getItem("itemsPerPage") !== null) {
      let savedCount: any = localStorage.getItem("itemsPerPage");
      endCount = parseInt(savedCount);
    }
    if (
      localStorage.getItem("dropDownFilterVal") !== undefined &&
      localStorage.getItem("dropDownFilterVal") !== null
    ) {
      var tempArr = localStorage.getItem("dropDownFilterVal");
      if (tempArr !== null) var dropDownValues = JSON.parse(tempArr);
      var property = dropDownValues[0].property;
      var direction = dropDownValues[0].direction;
    }

    if (values === null) {
      filter = null;
      data = {
        filters: {
          and: [
            {
              match: "exact",
              name: "auctionId",
              value: props.match.params.auctionId,
            },
          ],
        },
        page: {
          start: startCount,
          count: endCount,
          sort: {
            orders: [
              {
                property:
                  property !== null && property !== undefined
                    ? property
                    : "serialNo",
                direction:
                  direction !== null && direction !== undefined
                    ? direction
                    : "ASC",
              },
            ],
          },
        },
      };
    } else {
      body = [];
      values.map((item: any, index: number) => {
        if (item.name === "auctionId") {
          values.splice(index, 1);
        }
      });
      // changing and condition to or condition just for checking advance search issue for harshit/
      body = values;
      if (localStorage.getItem("tabSelectedVal") === "1") {
        // body.push(
        //   {
        //     match: "exact",
        //     name: "auctionId",
        //     value: props.match.params.auctionId,
        //   },
        //   {
        //     match: "exact",
        //     name: "parcelType",
        //     value: "Diamond",
        //   }
        // );
        data = {
          filters: {
            and: [
              {
                match: "exact",
                name: "auctionId",
                value: props.match.params.auctionId,
              },
              {
                match: "exact",
                name: "parcelType",
                value: "Diamond",
              },
            ],
            or: values,
          },
          page: {
            start: startCount,
            count: endCount,
            sort: {
              orders: [
                {
                  property:
                    property !== null && property !== undefined
                      ? property
                      : "serialNo",
                  direction:
                    direction !== null && direction !== undefined
                      ? direction
                      : "ASC",
                },
              ],
            },
          },
        };
      } else {
        let andArr = [];
        andArr.push(
          {
            match: "exact",
            name: "auctionId",
            value: props.match.params.auctionId,
          },
          {
            match: "exact",
            name: "parcelType",
            value: "Parcel",
          }
        );
        data = {
          filters: {
            or: body,
            and: andArr,
          },
          page: {
            start: startCount,
            count: endCount,
            sort: {
              orders: [
                {
                  property:
                    property !== null && property !== undefined
                      ? property
                      : "serialNo",
                  direction:
                    direction !== null && direction !== undefined
                      ? direction
                      : "ASC",
                },
              ],
            },
          },
        };
      }
      if (isCustomCoode === undefined) {
        filter = body;
        if (localStorage.getItem("tabSelectedVal") === "1") {
          localStorage.setItem("filteredValues", JSON.stringify(body));
        } else {
          localStorage.setItem("filteredParcelValues", JSON.stringify(body));
        }
      }
    }
    await filterCAllback(data);
  };

  const filterCAllback = (dataVal: any) => {
    if (selectcurrentApiStartCountValue === 0) {
      callApi(dataVal);
    } else {
      dispatch(reSetLiveCurrentApiStartCount());
    }
  };

  if (timezone !== null) {
    timezone = localStorage.getItem("timezoneName");
  }
  let auctionDate = props.match.params.auctionDate;
  let previewTime = moment.utc(auctionDate);
  if (previewTime !== null || previewTime !== undefined) {
    let result = moment.tz(previewTime, timezone);
    let resultFormatter = result.format();
    if (resultFormatter !== "Invalid date") {
      let splitDateTime = resultFormatter.split("T", 2);
      date = splitDateTime[0];
    }
  }

  const closeModal = () => {
    Auth.logout();
    props.history.replace("/");
  };

  const textSearchListing = (value: any) => {
    let filter: any;
    var pattern = /^[0-9,]*$/g;
    if (!pattern.test(value)) {
      filter = {
        or: [
          {
            match: "anywhere",
            name: "remarks",
            value: value,
          },
          {
            match: "anywhere",
            name: "productDescription",
            value: value,
          },
        ],
      };

      const getData = setTimeout(() => {
        const data = {
          filters: filter,
        };

        if (value === "" || value.length === 1) {
          setTextSearchArray([]);
        } else {
          if (value.length > 1) {
            Auth.postRequest(
              productTextSearch,
              data,
              (status: any, response: any) => {
                if (status === 200) {
                  setTextSearchArray(response.results);
                }
              }
            );
          }
        }
      }, 800);

      return () => clearTimeout(getData);
    }
  };

  const getMyBids = (auctionId: number | string, direction: string | null) => {
    if (!oAuth()) {
      props.history.push("/");
    } else {
      setOnAction(true);
      Auth.getRequest(
        `${auctionProductMyBidsUrl}?auctionId=${props.match.params.auctionId}&order=${direction}`,
        (status: any, response: any) => {
          if (status === 200) {
            if (response !== undefined) {
              localStorage.setItem(
                "currentStart",
                JSON.stringify(data.page.start)
              );
              let localArray = response.results;
              setIsLoading(false);
              setOnAction(false);
              setTotalElements(1);
              setAuctionProducts(localArray);
              if (
                localStorage.getItem("currentPDItem") !== null &&
                localStorage.getItem("currentPDItem") !== undefined
              ) {
                const id: any = localStorage.getItem("currentPDItem") || "";
                var element: any = document.getElementById(id);
                if (element !== undefined && element !== null) {
                  element.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "nearest",
                  });
                }
                localStorage.removeItem("currentPDItem");
              }
            }
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 505) {
            setApiTimeout(true);
            setOnAction(false);
          } else if (status === 404) {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(`${t("common:invalidRequest")}`);
            setAlertType("error");
            setOpenToast(true);
          } else {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(response[0].message);
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };

  const autoCompleteSearch = (val: any) => {
    localStorage.setItem("optionValue", val);
    localStorage.setItem("currentStart", "0");
    if (selectcurrentApiStartCountValue === 0) {
      getAuctionProducts(props.match.params.auctionId);
    } else {
      dispatch(reSetLiveCurrentApiStartCount());
    }
  };

  const handleMyBids = (auctionsProduct: any) => {
    if (auctionsProduct.length > 0) {
      setAuctionProducts(auctionsProduct);
      setTotalElements(auctionsProduct.length);
    } else {
      setAuctionProducts([]);
      setTotalElements(1);
    }
  };

  const sortData = (values: any) => {
    var tempArr: any;
    if (values === "lowestCarat") {
      if (
        localStorage.getItem("dropDownFilterVal") !== undefined &&
        localStorage.getItem("dropDownFilterVal") !== null
      ) {
        localStorage.removeItem("dropDownFilterVal");
      }
      tempArr = [{ property: "carat", direction: "ASC" }];
      localStorage.setItem("dropDownFilterVal", JSON.stringify(tempArr));

      getAuctionProducts(props.match.params.auctionId);
    } else if (values === "highestCarat") {
      if (
        localStorage.getItem("dropDownFilterVal") !== undefined &&
        localStorage.getItem("dropDownFilterVal") !== null
      ) {
        localStorage.removeItem("dropDownFilterVal");
      }
      tempArr = [{ property: "carat", direction: "DESC" }];
      localStorage.setItem("dropDownFilterVal", JSON.stringify(tempArr));

      getAuctionProducts(props.match.params.auctionId);
    } else if (values === "lowestJpy") {
      if (
        localStorage.getItem("dropDownFilterVal") !== undefined &&
        localStorage.getItem("dropDownFilterVal") !== null
      ) {
        localStorage.removeItem("dropDownFilterVal");
      }

      tempArr = [{ property: "caratMinimumPrice", direction: "ASC" }];
      localStorage.setItem("dropDownFilterVal", JSON.stringify(tempArr));

      getAuctionProducts(props.match.params.auctionId);
    } else if (values === "highestJpy") {
      if (
        localStorage.getItem("dropDownFilterVal") !== undefined &&
        localStorage.getItem("dropDownFilterVal") !== null
      ) {
        localStorage.removeItem("dropDownFilterVal");
      }
      tempArr = [{ property: "caratMinimumPrice", direction: "DESC" }];
      localStorage.setItem("dropDownFilterVal", JSON.stringify(tempArr));
      getAuctionProducts(props.match.params.auctionId);
    } else if (values === "lowestBids") {
      getMyBids(props.match.params.auctionId, "ASC");
    } else if (values === "highestBids") {
      getMyBids(props.match.params.auctionId, "DESC");
    }
  };

  const filterSort = (price: any, direction: any) => {
    let order = "";
    if (isSort === false) {
      order = "desc";
    } else {
      order = "asc";
    }
    setIsSort(!isSort);
    sort(price, order);
  };

  const sort = (value: any, direction: any) => {
    dataSorting(value, direction);
  };

  const dataSorting = (values: any, direction: any) => {
    let tempArr: any;
    if (direction === "asc") {
      tempArr = [{ property: values, direction: "ASC" }];
      localStorage.setItem("dropDownFilterVal", JSON.stringify(tempArr));
    } else {
      tempArr = [{ property: values, direction: "DESC" }];
      localStorage.setItem("dropDownFilterVal", JSON.stringify(tempArr));
    }
    localStorage.setItem("currentStart", "0");
    // currentPage.current = 0;
    if (selectcurrentApiStartCountValue === 0) {
      getAuctionProducts(props.match.params.auctionId);
    } else {
      dispatch(reSetLiveCurrentApiStartCount());
    }
  };

  //list view active and deactive
  const listGridview = () => {
    setUiBoxView(true);
    dispatch(resetLiveIsGridView());
  };

  const Gridview = () => {
    dispatch(setLiveIsGridView({ isLiveGridView: false }));
    setUiBoxView(false);
  };
  const theme = useTheme();
  const notSmallDevices = useMediaQuery(theme.breakpoints.between("md", "xl"));

  const openMypage = () => {
    props.history.push(`/my-page`);
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      console.log(filters);
    }, 700);

    return () => clearTimeout(getData);
  }, [filters]);

  const selectItemInGridView = (id: any) => {
    if (!isReadOnlyUser) {
      let arr: any = [];
      if (exportIds.indexOf(id) !== -1) {
        arr = exportIds.filter((x: any) => x !== id);
      } else {
        arr = [...exportIds];
        arr.push(id);
      }
      if (arr.length > 10) {
        setShowAlertMessage(
          `${t("common:Tender:validation:productSelection")}`
        );
        setAlertType("error");
        setOpenToast(true);
      }
      setexportIds(arr.slice(0, 10));
    } else {
      setShowAlertMessage(`You are the read only user.`);
      setAlertType("error");
      setOpenToast(true);
      setTimeout(() => {
        setOpenToast(false);
      }, 3000);
    }
  };

  const bulkWatchlistHandler = () => {
    if (!isReadOnlyUser) {
      if (exportIds.length > 0) {
        setOpenBulkWatchModal(true);
      } else {
        setShowAlertMessage(`Please Select Product to watch.`);
        setAlertType("error");
        setOpenToast(true);
        setTimeout(() => {
          setOpenToast(false);
        }, 2000);
      }
    } else {
      setShowAlertMessage(`You are the read only user.`);
      setAlertType("error");
      setOpenToast(true);
      setTimeout(() => {
        setOpenToast(false);
      }, 3000);
    }
  };

  const renderBulkWatchlistModal = () => {
    return (
      <StyledModal open={openBulkWatchModal}>
        <BulkWatchlistPopup
          popupClose={() => {
            setOpenBulkWatchModal(false);
            setexportIds([]);
          }}
          auctionId={props.match.params.auctionId}
          productIndexs={exportIds}
          items={auctionProducts}
          auctionUserId={""}
          callApi={getAuctionProducts}
        />
      </StyledModal>
    );
  };

  const prebidCallingApi = () => {
    getAuctionProducts(props.match.params.auctionId);
  };

  const selectAllProduct = () => {
    if (!isReadOnlyUser) {
      if (exportIds.length !== 0) {
        setexportIds([]);
      } else {
        let arr: any = [];
        auctionProducts.map((productVal: any, index: any) => {
          if (auctioneerSellerUserId !== productVal.sellerAuctionUserId) {
            arr.push(index);
          }
        });
        setexportIds(arr.slice(0, 10));
        if (arr.length > 10) {
          setShowAlertMessage(
            `${t("common:Tender:validation:productSelection")}`
          );
          setAlertType("error");
          setOpenToast(true);
        }
      }
    } else {
      setShowAlertMessage(`You are the read only user.`);
      setAlertType("error");
      setOpenToast(true);
      setTimeout(() => {
        setOpenToast(false);
      }, 3000);
    }
  };

  return (
    <AppLayout {...props}>
      {apiTimeout === true ? (
        <ApiTimeout t={t} {...props} />
      ) : (
        <Grid>
          {isLoading ? (
            <div className="loader">
              <LoadingSpinner />
            </div>
          ) : (
            <StyledLayoutGrid container={true}>
              <StyledBoxContent title="Click here for advance search">
                <StyledDropDown
                  src="/images/assets/doubledown.png"
                  alt="d"
                  style={{
                    transform: isFiltersOpen
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  }}
                  onClick={handleFilters}
                />
              </StyledBoxContent>
              {isFiltersOpen === true && (
                <div>
                  <TabLayout {...props} t={t} tabClick={tabItemClick} />
                  {isShowParcelItem === false ? (
                    <ProductFilter
                      {...props}
                      t={t}
                      filteredAuctions={filteredAuction}
                      auctionId={props.match.params.auctionId}
                      isPreviewPage={false}
                    />
                  ) : (
                    <ParcelItemFilter
                      {...props}
                      t={t}
                      filteredParcelAuctions={filteredAuction}
                      auctionId={props.match.params.auctionId}
                      isPreviewPage={false}
                    />
                  )}
                </div>
              )}
              {isGridView === false && notSmallDevices && (
                <CoreDataGridofProductLive
                  t={t}
                  data={auctionProducts}
                  totalElements={totalElements}
                  itemPerPage={itemPerPage}
                  value={getUpdatedProducts}
                  noProductFound={handleMyBids}
                  sortingValue={sortData}
                  wathlistPage={bulkWatchlistHandler}
                  Gridview={Gridview}
                  listGridview={listGridview}
                  uiBoxView={uiBoxView}
                  isJewllery={true}
                  filteredAuction={filteredAuction}
                  reduxItemPerPage={selectItemPerPageValue}
                  isDesktopDevices={notSmallDevices}
                  myPageRedireaction={openMypage}
                  selectAllInGridView={selectAllProduct}
                  prebidEndTime={prebidEndTime}
                  autoCompleteSearch={autoCompleteSearch}
                  textSearchListing={textSearchListing}
                  setTextSearchArray={setTextSearchArray}
                >
                  {auctionProducts.length > 0 ? (
                    <TableContainer
                      products={auctionProducts}
                      auctionName={props.match.params.auctionName}
                      auctionDate={
                        date !== "" ? date : props.match.params.auctionDate
                      }
                      auctionId={props.match.params.auctionId}
                      watchlist={addToWatchList}
                      removeFromWatchlist={removeFromWatchlist}
                      dataSort={filterSort}
                      getUpdateData={getProducts}
                      totalElements={totalElements}
                      itemPerPageForTable={itemPerPageForTable}
                      endCount={endCount}
                      page={currentPage.current}
                      setexportIds={setexportIds}
                      selectedExportId={exportIds}
                      prebidCall={prebidCallingApi}
                    />
                  ) : (
                    <Grid container={true} spacing={0}>
                      <StyledTextWrapper item xs={12}>
                        {t("common:preview:noProductFound")}
                      </StyledTextWrapper>
                    </Grid>
                  )}
                </CoreDataGridofProductLive>
              )}
              {isGridView === true
                ? notSmallDevices && (
                    <CoreDataGridofProductLive
                      t={t}
                      data={auctionProducts}
                      totalElements={totalElements}
                      itemPerPage={itemPerPage}
                      value={getUpdatedProducts}
                      noProductFound={handleMyBids}
                      sortingValue={sortData}
                      wathlistPage={bulkWatchlistHandler}
                      Gridview={Gridview}
                      listGridview={listGridview}
                      uiBoxView={isGridView}
                      reduxItemPerPage={selectItemPerPageValue}
                      filteredAuction={filteredAuction}
                      isDesktopDevices={notSmallDevices}
                      myPageRedireaction={openMypage}
                      selectAllInGridView={selectAllProduct}
                      prebidEndTime={prebidEndTime}
                      autoCompleteSearch={autoCompleteSearch}
                      textSearchListing={textSearchListing}
                      setTextSearchArray={setTextSearchArray}
                    >
                      {auctionProducts.length > 0 ? (
                        <ProductListView
                          {...props}
                          auctionName={props.match.params.auctionName}
                          auctionDate={date}
                          t={t}
                          watchlist={addToWatchList}
                          removeFromWatchlist={removeFromWatchlist}
                          dataSort={filterSort}
                          selectedExportId={exportIds}
                          selectItemInGridView={selectItemInGridView}
                          prebidCall={prebidCallingApi}
                        />
                      ) : (
                        <Grid container={true} spacing={0}>
                          <StyledTextWrapper item xs={12}>
                            {t("common:preview:noProductFound")}
                          </StyledTextWrapper>
                        </Grid>
                      )}
                    </CoreDataGridofProductLive>
                  )
                : null}
              {notSmallDevices || (
                <CoreDataGridofProductLive
                  t={t}
                  data={auctionProducts}
                  totalElements={totalElements}
                  itemPerPage={itemPerPage}
                  value={getUpdatedProducts}
                  noProductFound={handleMyBids}
                  sortingValue={sortData}
                  wathlistPage={bulkWatchlistHandler}
                  Gridview={Gridview}
                  listGridview={listGridview}
                  uiBoxView={true}
                  reduxItemPerPage={selectItemPerPageValue}
                  filteredAuction={filteredAuction}
                  isDesktopDevices={notSmallDevices}
                  myPageRedireaction={openMypage}
                  selectAllInGridView={selectAllProduct}
                  prebidEndTime={prebidEndTime}
                  autoCompleteSearch={autoCompleteSearch}
                  textSearchListing={textSearchListing}
                  setTextSearchArray={setTextSearchArray}
                >
                  {auctionProducts.length > 0 ? (
                    <ProductListView
                      {...props}
                      auctionName={props.match.params.auctionName}
                      auctionDate={date}
                      t={t}
                      watchlist={addToWatchList}
                      removeFromWatchlist={removeFromWatchlist}
                      dataSort={filterSort}
                      selectedExportId={exportIds}
                      selectItemInGridView={selectItemInGridView}
                      prebidCall={prebidCallingApi}
                    />
                  ) : (
                    <Grid container={true} spacing={0}>
                      <StyledTextWrapper item xs={12}>
                        {t("common:preview:noProductFound")}
                      </StyledTextWrapper>
                    </Grid>
                  )}
                </CoreDataGridofProductLive>
              )}
              {openToast === true && (
                <Alert
                  showAlertMessage={showAlertMessage}
                  alertType={alertType}
                />
              )}
              {onAction === true && (
                <div className="loading">
                  <LoadingSpinner />
                </div>
              )}
              {userBlock === true && (
                <StyledModal open={userBlock}>
                  <BlockUserModal t={t} {...props} closeModal={closeModal} />
                </StyledModal>
              )}
              {openBulkWatchModal && renderBulkWatchlistModal()}
            </StyledLayoutGrid>
          )}
        </Grid>
      )}
    </AppLayout>
  );
};

export default ProductView;
