import {
  mypageExportExcel,
  mypageWatchlist,
  mypagesellApi,
  productUrl,
  watchlistUrl,
} from "../../common/config/app.endpoints";
import AuthService from "../auth/AuthService";

const Auth = new AuthService();

export const getPendingProduct = async () => {
  const response = await new Promise(async (res) => {
    const callBack = (status: any, data: any) => {
      res({ status, data });
    };
    Auth.getRequest(watchlistUrl, callBack);
  });
  const { data, status } = response as { data: any; status: number };
  return { response: data, status: status };
};

export const getProductDetails = async (productId: string) => {
  const response = await new Promise(async (res) => {
    const callBack = (status: any, data: any) => {
      res({ status, data });
    };
    Auth.getRequest(`${productUrl}/${productId}/detail`, callBack);
  });
  const { data, status } = response as { data: any; status: number };
  return { response: data, status: status };
};

export const myPagePostRequest = async (page: any, count: any,category: string, filters?: any, sortvalue?: any, orValue?: any) => {
  let categorybyeurl =
    category === "WatchList&Prebid"
      ? `${mypageWatchlist}`
      : `${mypagesellApi}?type=buy&productState=${category}`;
  let payload = {
    // filters: {
    //   and: filters !== undefined && filters.length !== 0 ? [{ ...filters }] : [],
    //   or: orValue !== undefined && orValue.length !==0 ? orValue : [],
    // },
    filters: {
      and: [
        ...(filters !== undefined && filters.length !== 0
          ? [{ ...filters }]
          : []),
        ...(orValue !== undefined && orValue.length !== 0 ? orValue : []),
      ],
      or: [],
    },
    page: {
      start: page,
      count: count,
      sort: {
        orders:
          sortvalue !== undefined && sortvalue.length !== 0
            ? { ...sortvalue }
            : [
                {
                  property: "serialNo",
                  direction: "ASC",
                },
              ],
      },
    },
  };
  const response = await new Promise(async (res) => {
    const callBack = (status: any, data: any) => {
      res({ status, data });
    };
    Auth.postRequest(categorybyeurl, payload, callBack);
  });
  const { data, status } = response as { data: any; status: number };
  return { response: data, status: status };
};

export const myPageSoldPostRequest = async (page: any, count: any, category: string, filters?: any, sortvalue?: any, search?: any) => {
  let payload = {
    filters: {
      and: [
        ...(filters !== undefined && filters.length !== 0
          ? [{ ...filters }]
          : []),
        ...(search !== undefined && search.length !== 0 ? search : []),
      ],
      or: [],
    },
    page: {
      start: page,
      count: count,
      sort: {
        orders:
          sortvalue !== undefined && sortvalue.length !== 0
            ? { ...filters }
            : [
                {
                  property: "serialNo",
                  direction: "ASC",
                },
              ],
      },
    },
  };
  let selectedCategory = category === "All" ? "" : category;
  const response = await new Promise(async (res) => {
    const callBack = (status: any, data: any) => {
      res({ status, data });
    };
    Auth.postRequest(
      `${mypagesellApi}?type=sell&productState=${selectedCategory}`,
      payload,
      callBack
    );
  });
  const { data, status } = response as { data: any; status: number };
  return { response: data, status: status };
};

export const exportProducts = async (ids: any) =>{
    const response = await new Promise(async (res) => {
      const callBack = (status: any, data: any) => {
        res({ status, data });
      };
      Auth.postRequestForExcel(`${mypageExportExcel}`, { ids: ids }, callBack);
    });
    const { data, status } = response as { data: any; status: number };
    if (response !== undefined) {
      const url = window.URL.createObjectURL(
        new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Products.xlsx`);
      document.body.appendChild(link);
      link.click();
    }
    return { response: data, status: status };
}

export const getPendingProductsearch = async (value: string) => {
  const response = await new Promise(async (res) => {
    const callBack = (status: any, data: any) => {
      res({ status, data });
    };
    Auth.getRequestWithoutAuthorization(``, callBack);
  });
  const { data, status } = response as { data: any; status: number };
  return { response: data, status: status };
};

export const addOrRemoveWatchlist = async (url: string) => {
  const response = await new Promise(async (res) => {
    const callBack = (status: any, data: any) => {
      res({ status, data });
    };
    Auth.postRequest(`${productUrl}/${url}`, "payload", callBack);
  });
  const { data, status } = response as { data: any; status: number };
  return { response: data, status: status };
};

  export function refreshPage() {
    window.location.reload();
  }