import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";

import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { backgroundGradient } from "../../common/materials/LinearGradient";
import { notifySuccess, notifyError } from "../../common/elements/AlertNotify";
import { AuctionState } from "../../enums/auctionEnum";

import {
  watchProduct,
  unWatchProduct,
} from "../../redux/slice/product/productSlice";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { StyledCloseicon } from "../products/preview/TendorPreviewView";

const faHeartProps = faHeart as IconProp;

const StyledContainer = styled(Grid)``;
const StyledWishContainer = styled(Grid)`
  background: ${backgroundGradient};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  position: relative;
`;
const StyledNotStartedContainer = styled(Grid)`
  cursor: default;
  background: ${backgroundGradient};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
  }
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
`;

interface IProps {
  productId: string;
  auctionId: string;
  watched: any;
  state: string | null;
  auctionStartDate: string;
  fetchData: () => void;
  onCardClick: () => void;
  onWatch: (auctionId: string, productId: string, isWatch: boolean) => void;
}

const AddorNotToWatchist = (props: IProps) => {
  const {
    productId,
    auctionId,
    watched,
    onWatch,
    auctionStartDate,
    onCardClick,
  } = props;
  let languageCode =
    localStorage.getItem("language") === "EN"
      ? "en"
      : localStorage.getItem("language") === "zh_CN"
      ? "zh_CN"
      : "jp";

  return (
    <>
      {watched === false || watched === undefined ? (
        <StyledContainer item={true} xs={2}>
          <StyledWishContainer
            item={true}
            xs={12}
            onClick={(e) => {
              props.onWatch(auctionId, productId, true);
            }}
          >
            <FontAwesomeIcon icon={faHeartProps} className="unwatch" />
          </StyledWishContainer>
          <StyledNotStartedContainer item={true} xs={12}>
            <StyledCloseicon
              src="/images/noteIcons/whiteNotes.png"
              onClick={() => {
                window.open(
                  `/${languageCode}/my-page/diamonds/details/${auctionId}/${productId}/${auctionStartDate}`,
                  "detailsWindowName",
                  "height=700px,width=800px,scrollbars,toolbar,status,resizable"
                );
              }}
            />
          </StyledNotStartedContainer>
        </StyledContainer>
      ) : (
        <StyledContainer item={true} xs={2}>
          <StyledWishContainer
            item={true}
            xs={12}
            onClick={(e) => {
              onWatch(auctionId, productId, false);
            }}
          >
            <FontAwesomeIcon
              icon={faHeartProps}
              className="watch"
              style={{ color: "red" }}
            />
          </StyledWishContainer>
          <StyledNotStartedContainer item={true} xs={12} onClick={() => onCardClick()}>
            <StyledCloseicon src="/images/noteIcons/whiteNotes.png" />
          </StyledNotStartedContainer>
        </StyledContainer>
      )}
    </>
  );
};

export default AddorNotToWatchist;
