import React from "react";
import EllipsisText from "../../common/elements/EllipsisText";
import { removeHttpsLink } from "../../common/GenericFunctions";

interface IProps {
  truncateLength: number;
  data: any;
  isTendor?: boolean;
}

const ParcelProductDescription = (props: IProps) => {
  const { truncateLength, data, isTendor } = props;
  const {
    code,
    parcelType,
    productDescription,
    color,
    carat,
    sieve,
    shape,
  } = data;

  const PARCEL_TYPE_PARCEL = "Parcel";

  // DIAMONDS
  const COLOR = color === undefined || color === null ? "" : color;
  // PARCEL
  const SIEVE_TYPE =
    sieve === undefined || sieve === null || sieve === "" ? "" : sieve;
  const PRODUCT_SHAPE = shape === undefined || shape === null ? "" : shape;
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  // Find all the URLs in the string
  var urls = productDescription ?? "".match(urlRegex);

  const newString: any = removeHttpsLink(productDescription);

  const PRODUCT_DESCRIPTION =
    productDescription === undefined || productDescription === null
      ? ""
      : urls
        ? newString
        : "";

  const CARAT_CT =
    carat === undefined || carat === null ? "" : `${carat} ${"Ct"}`;

  const getParcelDescription = () => {
    return `${COLOR} ${PRODUCT_SHAPE} ${SIEVE_TYPE} ${CARAT_CT} ${PRODUCT_DESCRIPTION}`;
  };

  const getDescription = () => {
    let description = "";

    if (parcelType === PARCEL_TYPE_PARCEL) {
      description = getParcelDescription();
    }

    return `${code} ${description}`;
  };

  return (
    <span title={getDescription()}>
      <EllipsisText
        text={getDescription()}
        length={truncateLength}
        tail={"..."}
      />
    </span>
  );
};

export default ParcelProductDescription;
