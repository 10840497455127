export default {
  common: {
    hi: "こんにちは",
    bid: "入札",
    menuText: "Menu",
    createNow: "請求受付中",
    liveAuc: "ライブオークション",
    prevAuc: "最近行われたオークション",
    live: "ライブオ",
    previous: "最近行われたオ",
    auc: "クション",
    message: "メッセージ",
    filSearch: "検索条件",
    h2l: "高から低",
    l2h: "低額から高額へ",
    filSrch: "検索条件",
    refine: "絞り込む",
    reset: "リセット",
    newUserSignUp: "New User? Sign Up here",
    jwlry: "宝飾",
    items: "アイテム",
    diamond: "ダイヤモンド",
    signIntoYourAcc: "ログイン",
    zipangText:
      "Zipang Auctions is an exclusive B2B auction for our verified industry business houses across the World",
    hiw: "どのように?",
    whyus: "何故私たちが選ばれるのか？",
    videoUrl: "https://youtu.be/aGfOPky_VrU",
    savedSuccessfully: "Saved Successfully",
    updatedSuccessfully: "Updated Successfully",
    deletedSuccessfully: "Deleted Successfully",
    videoUrlNew: "https://www.youtube.com/watch?v=H9KB9zKM3Nc",
    logout: {
      logout: "ログアウト",
    },
    notificaiton: {
      notification: "Notification",
      new: "New",
      showAll: "SHOW ALL NOTIFICATIONS",
      all: "ALL",
      removeFromThe: "You are removed from the Auction.",
      markAsRead: "mark as read",
    },
    testimonials: {
      1: "My Auctions は今までで一番のオークションシステム。以前、別のオークションハウスを利用したがAuctionsは100％スムーズにできた",
      2: "My Auctions は、売る、入札するユーザーに使いやすいウエブサイト。皆さんはこのウエブサイトよりオンラインオークション開催のやり方を学んだら良いでしょう",
    },
    exploreAuctionsCards: {
      1: "ジパングオークションは、ダイヤモンドトレイダー達のために、B2Bの選ばれた人達だけの 日本をベースとするマーケットプ市場です。このオークションは、月ごとのライブ入札、ポップコーン入札をユーザーに提供します。商品は360°の回転映像で見ることができます。",
      2: "365日オークションはトレイダーと売り手の為の、ジュエリーをいつでもどこでも毎日, オークションできるプラットフォームです。",
      3: "いかなる小さな仕事でも、国際市場で未来の主催者になりえ、ビジネスの拡張の可能性があります。その他のオークションプログラムも昼夜サポートが可能です。",
    },
    whyUs: {
      1: {
        title: "国際展開",
        1: "手広くワールドワイドに日本の中古市場を提供",
        2: "5473の会社へのネットワークを提供",
        3: "あなたの商品開口率が90％",
        4: "あなたのローカルビジネスのグローバル化のお手伝い",
      },
      2: {
        title: "財布に優しい",
        1: "あなたの愛着あるジュエリーでより高額を得よう",
        2: "売り手に対し３％の低手数、0.5％キャンペーンで小ビジネスのサポート",
        3: "オンラインオークションを、低価格でレンタル",
        4: "自動リマインダーメール、FAX、請求書の無料サービス",
      },
      3: {
        title: "データセキュリティ",
        1: "全てのお客様の個人情報の暗号化で保護",
        2: "お客様からのいかなるデーターも正確に維持",
        3: "入札者のために何百ものKYCチェック",
        4: "私達の信頼熱いロジスティクスチームが、不正や詐欺を皆無にすることを保証",
      },
    },
    header: {
      logoText: "マイオークションズ",
      buttons: {
        addAsFriend: "友だち追加",
        login: "ログイン",
        loginNew: "参加",
        register: "登録",
        aboutUs: "会社情報",
        sellYDiamonds: "売る",
        buyDiamonds: "買う",
        buy: "買う",
        sell: "売る",
        ea: "オークションを見る",
      },
      menu: {
        jpa: "ジパングオークション",
        oa: "その他のオークション",
        "365a": "#365日",
        ma: "主催者はこちら",
        sd: "買う",
      },
    },
    subHeader: {
      logoText: "MY AUCTIONS",
      buttons: {
        whyUs: "なぜ私たち",
        howItWorks: "使い方",
        media: "メディア",
        aboutUs: "会社情報",
        contactUs: "お問合せ",
        testimonials: "お客様の声",
        team: "チームリーダーの紹介",
        incubator: "インキュベーター",
      },
    },
    sellPages: {
      success: "承りました。ご連絡をお待ちください！",
      title: "売る",
      heading: "迅速かつ正確な",
      heading1: "価格設定を！",
      subheading:
        "マイオークションは信頼のおけるビジネスパートナーです。宝石の価値を理解している世界的な5000社以上を獲得するために、あなたと一緒に行動します。大きな収益を上げることをお約束致します。",
      areYouBuss: "主催者をご希望ですか？",
      visitSell: "こちらにアクセス",
      or: "または",
      desOfStone: "石材の説明",
      prodDesc: "商品説明（ある場合",
      setInJewel: "宝石類",
      uploadPhoto: "写真のアップロード",
      uploadCert: "証明書のアップロード",
      bulkUpload: "一括アップロード",
      companyName: "会社名",
      enterValue: "JPY (値を入力してください)",
      estimation: "他から見積もりを取る",
      select: "下記のどれかをお選びください。 :",
      click: "Click here",
      note: "Note:",
      options: {
        1: "1. 見積もりのために直接オフィスへ訪問します。",
        2: "2. 見積もり用配送セットを送って欲しいです。",
        3: "3. CGL(中央宝石研究所）へダイヤモンドを鑑定に出したいです。",
        note: "またはCをお選びになった場合は、送料はこちらで負担いたします。",
        note2: "お取引になった場合、身分証明書のコピーが必要になります。",
      },
    },
    hero: {
      0: {
        heading1: "売却と購入の",
        heading2: "お手伝いをします",
        subHeading: "日本で最も急成長をとげているオンラインオークションです。",
      },
      1: {
        heading1: "日本初の中古宝飾品の",
        heading2: "オークション市場",
        subHeading: "低い手数料で、高い収益を得る。",
      },
      2: {
        heading1: "あなたの指先一つで",
        heading2: "日本の中古マーケットにアクセス",
        subHeading: "日本でしか手に入らない最高級のジュエリーを発掘しよう",
      },
      3: {
        heading1: "オークションウェブサイトを",
        heading2: "即座にゲット",
        subHeading: "弊社のエキスパート達が、あなたのビジネスをサポート。",
      },
      4: {
        heading1: `「365 Days」・「Buy It Now」が登場！`,
        heading2: "欲しい商品をその場で購入で",
        subHeading: "る新しいオークションです。",
      }
    },
    loader: {
      loading: "ローディング。。。",
      comingSoon: "近日発売",
    },
    languages: {
      en: "EN",
      jp: "日本語",
      ch: "CH",
    },
    button: {
      signIn: "サインイン",
      signInAsAuction: "主催者としてログイン",
      createAccount: "アカウント作成",
      cancel: "キャンセル",
      cancel_small: "キャンセル",
      save: "保存",
      changePassword: "パスワード変更",
      changePlan: "プラン変更",
      ok: "OK",
      filter: "絞り込み",
      search: "検索",
      searchRemark: "批評のリサーチ",
      reset: "リセット",
      note: "備考",
      preBid: "前入札",
      cancelPreBid: "前入札を解除",
      participate: "参加",
      join: "参加",
      advanceSearch: "詳細検索",
      basicSearch: "ベーシック",
      otpSignIn: "OTPによるサインイン",
      passwordSignIn: "Sign in using Password",
      resendOtp: "OTP 再発行",
      resendOtpText: "再送信",
      seconds: "秒",
      resendOtpMinutes: "minutes",
      resendOtpSeconds: "秒",
      verify: "VERIFY",
      savePassword: "SET PASSWORD",
      savePasswordText:
        "Set your user account password to continue to My Auctions",
    },
    menu: {
      home: "ホーム",
      preview: "下見",
      auction: "オークション ",
      watchlist: "お気に入り商品",
      invoice: "請求書",
      profile: "プロフィール",
      inquiry: "お問い合わせ",
      pendingProduct: "保留中の製品",
      productAuction: "365 DAYS AUCTION",
      mypage: "MY-PAGE",
    },
    footer: {
      copyrightFooter: "All Rights Reserved. ",
      company: "マイオークションズ",
      copyright: "2020-21 All Rights Reserved. ",
      officeNumber: "O: +81358161188",
      faxNumber: "Fax: +81358161189",
      officeAddressOld: "1100005 東京都台東区上野ファーストビル3-10-1 201",
      officeAddress: "〒110-0016 東京都台東区台東４丁目１０−7",
      emailId: "contact@myauctions.jp",
      hokuyu: {
        officeNumber: "O: 09043537782",
        faxNumber: "Fax: -",
        officeAddress: "新宿2丁目7-6 , 新宿区 , 東京都, Japan",
        emailId: "koakuma2001@docomo.ne.jp",
      },
      zipangcompany: "Zipang Auctions HK Ltd. ",
      zipangofficeAddress: "Unit 11 5F Block A Focal Industrial Centre",
      zipangofficeAddresssecond: "21 Man Lok St, Hung Hom, Hong Kong",
    },
    subdomainFooter: {
      emailId: "mjdiamondsco@gmail.com",
      company: "MY AUCTIONSのパワー",
      hokuyu: {
        company: "株式会社　北友",
        officeNumber: "09043537782",
        officeAddress: "新宿2丁目7-6 , 新宿区 , 東京都, Japan",
        emailId: "koakuma2001@docomo.ne.jp",
      },
    },
    noMoreResultsToShow: "表示するものはこれしかありません",
    socialMedia: {
      facebook: "フェイスブック",
      pinterest: "ピンタレスト",
      twitter: "ツイッター",
    },
    colorFilter: {
      all: "All",
      pink: "Pink(Sold)",
      orange: "Orange(Hold)",
      white: "White(Unsold)",
    },
    landingPage: {
      heading: "世界初オンライン・レンタルオークション・ウェブサイト",
      newHeading: "All Auction Tools at One Portal",
      category: {
        home: "ホーム",
        diamonds: "ダイヤモンド",
        bags: "鞄",
        accessories: "ファッション小物",
        watch: "時計",
        shoes: "靴",
        jewellery: "宝飾",
        septTitle: '9月のオークション'
      },
      text: "Having an online auction has never been easier. We bring together the power of world class ecommerce technologies and make them easy and accessible for your business success.",
      // text: '今までのオンラインオークションのウエブサイト作成は簡単ではありませんでした。しかし、私たちはワールドクラスの技術を駆使し、グローバルにアクセスが簡単。更なるビジネスの発展を可能にしました。MY AUCTIONSサイト内のスペースをレンタルする事で自社オークションを開催する事が出来ます。',
      whyus: "なぜ私たち",
      becomeaMember: "会員になる",
      freeTrial: "14日間の無料お試し期間",
      pointers: {
        firstOption: "プライバシーの保証",
        secondOption: "入札システムのグローバル化",
        thirdOption: "コスト削減",
        fourthOption: "グローバル化",
      },
      keyPointers: {
        firstPointer: {
          firstOption:
            "お客様の個人情報を保護し、お客様の情報に基づいて正確性を保持します。",
          secondOption:
            "個人情報をインターネット経由で送信する場合は、すべて暗号化されますのでご安心下さい。",
        },
        secondPointer: {
          firstOption: "会員は当社にて入会審査に認証された方のみ",
        },
        thirdPointer: {
          firstOption: "人件費　1/4に削減",
          secondOption: "オークション会場レンタル費　1/5に削減",
          thirdOption: "無料の自動リマインダー通知メール",
          fourthOption: "無料の自動請求書作成",
        },
        fourthPointer: {
          firstOption:
            "出品された商品は日本だけでなく、世界中の会員に公開されます。",
          secondOption: "外国語のスキルは必要ありません。",
        },
      },
      howItWorks: "使い方",
      list: {
        noOfProducts: "商品件数",
        preview: "下見日(UTC)",
        auction: "オークション日(UTC)",
        participate: "参加",
      },
      floatIcon: "会員になる",
      banner: {
        first: {
          firstText: "私たちと一緒にオンラインでオークションをしましょう",
          secondText: "年間費　$99　（税抜）",
        },
        second: {
          firstText: "招待コードがあれば年会費無しで入札が可能です",
          secondText: "毎年 $0 を支払う",
          thirdText:
            "招待コードをお持ちでない場合は、 年会費$99(税抜)で入札者会員になれます",
        },
        third: {
          firstText: "オークション開催者と入札者、両方",
          secondText: "年会費$149（税抜）",
        },
        fourth: {
          firstText: "オークション用のサイトスペースを探していませんか？",
          secondText: "答えはマイオークションズで借りる！",
          thirdText: "右にスライドして価格を確認してください",
        },
      },
      whyMyAuction: {
        heading: "なぜ私たち",
        firstLine: "従来の方法",
        secondLine: "マイオークションズの場合",
      },
      testMoniels: {
        heading: "メディア",
        desFrist: "昨今のオークションで最も公平性なオークションです",
        desSecond: "日本の中で最も使い易くわかりやすいシステムです",
        desThird: "素晴らしい売買システム!",
        desFourth: "即座に対応してくれるカスタマーサポート",
        japanPrecious: "Japan Precious Summer 2021",
        jewelristVol157: "Jewelrist 2021 Vol.157",
        jewelristVol158: "Jewelrist 2021 Vol.158",
        nominee: "Top 100 tech Innovators & Influencers",
      },
    },
    login: {
      signInAuctioneer: "Sign in as Auctioneer",
      title: "ログイン",
      validation: {
        invalidEmailorPassword: "無効な資格情報",
        newinvalidEmailorPassword: "改めてご確認お願いいたします",
        userNotRegistered: "ユーザーが登録されていません",
        restrictedUserLine1:
          "アカウントが制限されています 「myauctionsにお問い合わせください。メール：contact@myauctions.jp",
        registeredAsAuctioneer: "このIDは競売人としてのみ登録されています",
      },
      labels: {
        email: "Eメール",
        password: "パスワード",
      },
      forgotPassword: "パスワードを忘れた",
      dontHaveAccount: "アカウントを持っていない",
      registerNow: "今登録する",
    },
    register: {
      title: "登録",
      occupation: {
        auctioneer: "競売人",
        bidder: "入札者",
        auctioneerAndBidder: "競売人と入札者",
      },
      labels: {
        firstName: "名",
        lastName: "姓",
        email: "Eメール",
        company: "会社",
        occupation: "会員種別",
        address: "〒 郵便番号住所",
        city: "市区町村",
        country: "国",
        state: "都道府県",
        phone: "電話",
        invitationCode: "招待コード",
      },
      placeholder: {
        firstName: "例-太郎",
        lastName: "例-山田",
        email: "例-taroyamada@abc.com",
        company: "例-ABCCo。Ltd.",
        address: "例-上野、1-1-1建物名",
        city: "例-渋谷",
        country: "例-日本",
        state: "例-東京",
        phone: "例-1234567809",
        invitationCode: "例-123456",
        regexphone: "例- 1234567809",
      },
      iAgree: "同意します",
      robotText: "我不是一个机器人。",
      buttonText: "登録",
      termsandCondition: "サービス規約",
      and: "アンド",
      privacyPolicy: "個人情報保護方針",
      alreadyAccount: "すでにアカウントをお持ですか？",
      login: " ログイン",
      errorMessage: {
        onfirstName: "正しい名を入力して下さい",
        onlastName: "正しい姓を入力して下さい",
        onCheckTerms: "利用規約に同意する",
        onMobileNumber: "正しいの携帯番号を入力して下さい",
        onEmail: "正しいEメールを入力して下さい.",
        emptyEmail: "Please enter email address.",
        newonEmail: "メールが認識されません",
        occupationNotSelect: "会員種別を選んでください。",
        alreadyRegistered: "同じEメールで登録されているユーザーがおります。",
        pendingRequest: "レビュー保留のユーザーが既にいます。",
        invitationCode: "招待コードには数字を使用してください",
        phoneNumber: "正しく電話番号を入力してください",
        notSeletBidder:
          "招待コードを使用する際、会員種別には「入札者」を選択してください。",
        profileVerification:
          "現在、プロフィールは精査中です。 プロフィールが確認され次第、連絡させて頂きます。",
        correctInvitationCode: "招待コードが正しくありません",
      },
      registerModal:
        "登録申請を受け取りました。 追ってメールでご連絡いたします。",
      subdoaminRegisterModal: "You are not registerd with Myauctions.",
      secondSubdoaminRegisterModal: "Please subscribe for complete access.",
    },
    profile: {
      title: {
        personalDetails: "個人詳細",
        bankDetails: "指定金融機関",
        shippingAddress: "配送先",
      },
      legends: {
        email: "Eメール",
        companyName: "会社名",
        contactNo: "電話番号",
        modeOfContact: "連絡方法",
        bankName: "銀行名",
        accountHolderName: "振込先",
        accountNo: "口座番号",
        ifscCode: "IFSCコード",
        bankAddress: "銀行住所",
        timezone: "タイムゾーン",
        companyAddress: "会社情報",
        guarantorName: "Guarantor Name",
        guarantorAddress: "Guarantor Address",
        referenceName: "参照名",
        referenceAddress: "住所",
        editProfile: "プロフィール編集",
        refbankName: "Bank Name",
        refaccountHolderName: "アカウントネーム",
        refaccountNo: "口座番号",
        refifscCode: "IFSCコード",
        refbankAddress: "Bank Address",
        validation: {
          enterCompanyName: "会社名を記入してください",
          enterCompanyAddress: "会社住所を記入してください",
          enterReferenceName: "参照名を入力してください",
          enterReferenceAddress: "参照アドレスを入力してください",
          enterContactNo: "電話番号を記入してください",
          enterContactNoVal: "連絡先番号をご記入ください",
          enterContactNoValregex: "連絡先番号をご記入ください 例- +1234567809",
          contactNoMustBe: "連絡先番号は、数字の必要があります",
          enterTimeZone: "タイムゾーンを選択してください",
          enterModeOfContact: "連絡方法を選択してください",
          enterCountry: "お国を選択してください",
          enterCurrency: "通貨を選択してください",
          enterContactNoValBtw:
            "Please enter valid 8 - 15 digit contact number",
        },
      },
      shippingAddress: {
        title: "配送先",
        labels: {
          blockNo: "マンション・ビル名",
          streetName: "番地",
          city: "市区町村",
          state: "都道府県",
          country: "国",
          company: "Company Name",
          address: "Address",
          phoneNo: "Phone No.",
          pinCode: "PinCode",
        },
      },
      bankDetails: {
        title: "銀行情報",
        labels: {
          holderName: "振込先",
          acNumber: "口座番号",
          ifscCode: "IFSCコード",
          bankName: "銀行名",
          bankAddress: "銀行住所",
        },
      },
      changePassword: {
        title: "パスワード変更",
        labels: {
          oldPassword: "古いパスワード",
          newPassword: "新しいパスワード",
          confirmPassword: "パスワードを確認する",
        },
        validation: {
          enterOldPassword: "古いパスワードを入力して下さい",
          enterNewPassword: "新しいパスワードを入力して下さい",
          enterConfirmPassword: "確認のパスワードを入力してください",
          incorrectOldPassword: "古いパスワードは正しくありません",
        },
      },
      forgotPassword: {
        title: "パスワードを忘れた",
        labels: {
          otp: "OTP",
          newPassword: "新しいパスワード",
          confirmPassword: "パスワードを確認する",
          email: "登録したEメールIDを入力して下さい",
        },
        button: {
          update: "パスワードを再設定する",
          continue: "OTPを送信する",
        },
        validation: {
          invalidOTP: "無効なOTP！",
          newinvalidOTP: "入力したコードを確認お願いいたします.",
          correctOTP: "正しいOTPを入力して下さい",
          enterPassword: "パスワードを記入してください。",
          notRegisteredEmail: "登録されたEメールIDではありません",
        },
        note: {
          otpNote: "OTP has been sent to your email Id and contact number.",
          newOtpNote: "OTP sent to your email Id and contact number.",
          emailNote:
            "下記にメールIDを入力後、パスワード再設定のOTPを送信いたします。",
        },
        onSuccessful: {
          passwordChange: "パスワードが変更されました。",
        },
      },
      changePlan: {
        receiveYourRequest: "リクエストを受け取りました",
        changeThePlan: '"プランを変更する"',
        getInTouch: "追ってメールでご連絡いたします。",
      },
      toast: {
        passwordUpdate: "パスワードが更新されました",
      },
      validation: {
        newAndConfirmPasswordNotMatch:
          "新しいパスワードと確認パスワードが一致しません。",
        newAndOldPasswordNotMatch:
          "新しいパスワードは古いパスワードとは異なる必要があります。",
      },
      optionMenu: {
        markAsDefault: "デフォルトとしてマーク",
        delete: "削除",
      },
      addMore: "さらに追加+",
      userType: "ユーザーのタイプ:",
      startDate: "開始日時:",
      endDate: "終了日時:",
      downloadInvoice: "請求書をダウンロードしてください。",
    },
    preview: {
      products: "商品",
      bidButton: 'こちらへ',
      noProductFound: "商品が見つかりません",
      noAuctionFound: "現在オークションはありません",
      addedToWatchList: "お気に入り",
      addedToWatchMsg: "お気に入りました",
      addWatchList: "お気に入り",
      carousalTitle: "カテゴリー",
      price: "価格",
      pieces: "個",
      ct: "Ct",
      pol: "ポリッシュ",
      sym: "シンメトリー",
      at: "@",
      linkCopied: "リンクがコピーされました",
      showAllProduct: "全ての商品を表示",
      tendor: {
        live: "ライブ入札",
        popcornBidding: "ポップコーン入札",
        allProducts: "全ての商品",
        myBids: "私の入札",
        timeLeft: "残りの時間 :",
        bulkBid: "一括入札",
      },
      productsList: {
        startingPrice: "開始価格",
        ro: "ラップオフ",
        preBiddingPrice: "前入札",
        serialNo: "#",
        pct: "（＠）",
        marqueeExchangeRate: "下見当日の為替で請求書を作成します．1ドル＝ ",
        yen: "円",
        timeLeft: "残りの時間 :",
      },
      filterName: {
        shape: "形状",
        color: "カラー",
        clarity: "クラリティ",
        grading: "鑑定機関",
        price: "価格",
        weight: "カラット",
        fancyColor: "ファンシーカラー",
        cutPolishSymmetry: "カット・ ポリッシュ   ． シンメトリー",
        cut: "CUT",
        startingPrice: "開始価格",
        jewellery: "種類",
        jewelleryCondition: "ランク",
      },
      productDetail: {
        itemDescription: "アイテム記述",
        share: "商品をシェア",
        copy: "Copy",
        viewFullScreen: "拡大",
        shareTooltip:
          "商品をシェア】をクリックするとクライアントに下記の商品ページ（価格、オークション名記載無）をシェアする事が出来ます．",
        watchTooltip:
          "あなたのお気に入り商品がオークションにかかる１０商品前に通知メールが届きます。",
        jewelleryShareTooltip:
          "ユーザーは、商品詳細ページを同僚とシェアすることができます。スターティングプライス、その合計金額などは、シェアされたリンク内では見えないようになっています。",
        tendorshareTooltip:
          "ユーザーは、商品詳細ページを同僚とシェアすることができます。スターティングプライス、その合計金額などは、シェアされたリンク内では見えないようになっています。",
        tendorDiamondshareTooltip:
          "User can share this product detail page with their colleague. Starting Price Pct., Amount of starting price, price per carrot and rap % will not be visible in shared link.",
      },
      preBidding: {
        jpy: "000円",
        dollar: "0$",
        jpyJewellery: "円",
        preBidding: "前入札　（＠）",
        enterAmount: "金額を入力",
        jwlEnterAmount: "Enter amount",
        validationOnCorrectAmount:
          "前入札（＠）は、開始価格よりも高くする必要があります",
        placeholderText: "前入札（＠）を入力する",
        preBidPopUpMessage: "前入札を確認してください。",
        JewPreBidding: "前入札",
      },
      notes: {
        notes: "注意",
        save: "保存",
        enterNote: "備考を記載",
        placeholderText: "商品について記載を追加する",
        notesValidation: "文字は200文字未満にする必要があります",
      },
      perItemDescription: {
        serialNo: "#",
        startingPrice: "開始価格/カラット",
        auctionStartDate: "開始予定日",
        startDate: "Start Date",
        itemCode: "アイテムコード",
        carat: "カラット",
        color: "カラー",
        clarity: "クラリティ",
        cutGrade: "カット",
        ex: "3EX",
        hnc: "HnC",
        flour: "蛍光性",
        lengthWidthHeight: "長さx幅x高さ",
        polish: "ポリッシュ",
        symmetry: "シンメトリー",
        preBidAmount: "前入札　（＠）",
        shape: "形",
        origin: "オリジン",
        lab: "鑑定機関",
        certificate: "証明書＃",
        fancyIntensity: "ファンシー強度",
        fancyColor: "ファンシーカラー",
        startingPricePerCarat: "開始価格　（＠）",
        sellingPricePerCarat: "カラットあたりの売価",
        amountOfStartingPrice: "開始価格の金額",
        pricePerCarat: "価格（米ドル）（＠）",
        rap: "ラップ％",
        rapPrice: "ラップ価格",
        exchangeRate: "為替レート",
        remarks: "備考",
        signInToView: "ログイン",
        noProductMessage: "商品は主催者によって削除されました。",
        sieve: "Sieve",
        noOfPieces: "個数,",
        piecesPCT: "キャラット毎の個数",
        pricePctUsd: "価格（米ドル）（＠)",
        totalCts: "トータルカラット",
        avgSize: "平均サイズ",
        totalAmount: "開始価格の金額",
        productDescription: "商品記述",
        jewelleryStartingPrice: "価格",
        jewelleryType: "種類",
        jewelleryCondition: "ランク",
        jewelryPricePctUsd: "価格（米ドル）（＠)",
      },
      tabLayout: {
        diamond: "ダイヤモンド",
        parcelItem: "材料（メレ）",
      },
      filters: {
        cutFilter: {
          cut: "カット",
          polish: "ポリッシュ",
          symmetry: "シンメトリー",
        },
        price: {
          ct: "/カラット",
          total: "総数",
        },
        fancyColor: {
          intensity: "濃さ",
          overTone: "オーバートーン",
          color: "カラー",
        },
        from: "から",
        to: "まで",
      },
      modal: {
        participateDontHaveCode:
          "招待コードが無い方は参加をクリック後、招待コードメールが届きます。",
        forparticipation: "",
        placeholderText: "招待コード",
        invitationCodeText:
          "オークションに参加する場合は招待コードを入力して下さい。",
        participateTextInvitee:
          "アカウントが制限されています。チームはまもなく連絡します。",
        participateText:
          "参加依頼を受け取りました。 追ってメールでご連絡いたします。",
        disableParticipation: "オークション開始の1時間前から参加可能です。",
      },
      search: {
        keyword: "キーワード",
      },
      pagination: {
        showing: "表示",
        page: "ページ",
        of: "/",
        results: "結果",
        lowestJpy: "最安値JPY",
        highestJpy: "最高値JPY",
        lowestCarat: "最低カラット",
        highestCarat: "最高カラット",
        itemsPerPage: "ページごとのアイテム",
        carat: "カラット",
        jpy: "JPY",
        myBids: "入札済",
        lowestBids: "最低入札額",
        highestBids: "最高入札額",
        export: "エクセルにダウンロード",
        inquiry: "お問い合わせ",
        select: "---Select---",
      },
    },
    home: {
      videoHeading: "オークションの流れ",
      auctionHeading: "開催中のオークション",
      calendar: "カレンダー",
      advertisementHeading: "一押しの商品",
      upcomingAuctions: "Upcoming Auctions",
      requestAppointment: " Request Appointment",
      legend: {
        legend: "スケジュール",
        preview: "下見",
        auction: "オークション",
        noAuction: "オークションなし",
        appointment: "Appointment",
      },
      auctionDetails: {
        serialNo: "#",
        timeLeft: "残りの時間",
        startingPricePerCarat: "開始価格",
        notes: "備考",
        preBiddingPrice: "前入札の価格",
        rapnetPrice: "ラプネット価格",
        rapnetPercentage: "ラプネットパーセンテージ",
        exchangeRate: "為替レート",
        remarks: "備考",
        rapOff: "ラップオフ",
      },
      modal: {
        soldOut: "売切れ",
        at: "@",
        congratulations: "おめでとうございます！",
        mazal: "マザル!!",
        text: "あなたは入札に勝ちました",
      },
      heading: {
        auction: "オークション",
        preview: "下見",
        fullDay: "終日",
      },
      list: {
        starting: "開始",
        price: "価格",
        participate: "参加",
      },
      noProductsAvailable: "製品はありません",
      downloadPdf: "ウェブサイトの使い方'ダウンロード",
      previweNotStarted: "下見はまだ始まっていません",
    },
    invoice: {
      header:
        "以下の請求書は、FYIの物です。もし、あなたが日本国外の入札者でしたら、お支払いには、どうぞ通関手続き請求書をご利用ください。",
      noInvoice: "請求書なし",
      buttons: {
        shippingAddress: "配送先",
        download: "ダウンロード",
        issueInvoice: "輸入請求書の発行",
        trackingButton: "追跡",
        invoiceButton: "請求書",
      },
      heading: {
        date: "日付",
        totalInvoice: "請求書の合計",
        totalAmountToPay: "請求金額（入札者より）",
        totalAmountToReceive: "支払金額 （主催者より）",
      },
      filter: {
        search: "検索",
        startDate: "開始日を選択して下さい",
        endDate: "終了日を選択して下さい",
      },
      validation: {
        selectAddress: {
          firstLine: "納品を希望している出荷先を選択して下さい。",
          secondLine:
            "配送先を選択しない場合は、既定の配送先住所に納品されます。",
          thirdLine:
            "備考- プロフィールページから配送先の追加または変更することが出来ます。",
        },
        noShippingAddressFound:
          "配送先を見つかりませんでした。プロフィールページに行って、配送先を追加して下さい。",
      },
      tracking: {
        recipientLable: "請求書発行",
        invoiceButton: {
          trackYourPackage: "トラックパッケージ",
          saveDetails: "保存",
        },
        trackingForm: {
          shippingCompanyName: "運送会社",
          awbTrackingNo: "AWB番号を入力してください",
          detailsSubmit: "情報は正常に送信されました。",
        },
        buyerDetails: {
          companyName: "名前",
          country: "国",
          deliveryAddress: "配送先住所",
          RecipientName: "受信者",
        },
        validation: {
          companyNameValidation: "Please select Company Name.",
          awbNumberValidation: "Please Enter Your AWB Tracking Number.",
        },
      },
    },
    auction: {
      auctionEnded: {
        firstLine: "ありがとうございます。",
        secondLine: "今度も宜しくお願い致します。",
        thirdLine: "次回のオークションの参加をお待ちしております",
      },
      auctionHold: {
        firstLine: "商品は保留になっています。",
        secondLine: "2日間以内に商品販売についてメールで知らせいたします。",
      },
      auctionPaused: {
        firstLine: "オークションは一時停止されます。",
        secondLine: "技術的な理由でオークションは一時停止されます。",
        thirdLine: "再開するまでお待ち下さい。",
      },
      auctionAboutToStart: {
        aboutToStart: "オークションがまもなく始まります",
      },
      auctionCancelled: {
        firstLine:
          "システムに技術的グリッチがあったのでオークションを終了します。",
        secondLine: "ご迷惑をお掛けして申し訳ございません",
      },
      notAbleToStart: "開始できませんでした。",
      technicalGlitch: {
        firstLine: "技術的な不具合によりオークションを開始できません。",
        secondLine: "更新については、それぞれの競売人に連絡してください。",
      },
      connectionLost: {
        firstLine: "接続切断。",
        secondLine:
          "ページを更新するか、インターネット接続を確認してください。",
      },
      automaticClock: {
        firstLine: "パソコンの時計が自動モードではありません。",
        secondsLine:
          "ウェブサイトで数秒の遅れに直面するかもしれません。これを回避するには、パソコンの時計を自動モードにしてください。",
      },
      sellerMessage: {
        firstLine: "入札制限",
        secondLine: "御社は商品の出品者です",
      },
      pasuedModal: {
        headerfirstLineFirst: "注意：オークションが始まります：",
        headerfirstLineSecond: "商品番号の後",
        headerSecondLine: "一休みしましょう！",
        biddingCont: "入札続行",
      },
      tenderExtendedTime: {
        firstLine: "まだ終わっておりません",
        secondLine: "15分前までに入札されて商品は",
        thirdLine: "30分の延長です",
        lastLine: "入札を続けて下さい！",
      },
    },
    watchList: {
      watchlistEmpty: "お気に入り商品は未だありません",
    },
    allValidations: {
      participate: {
        noAuctionFound: "現在オークションはありません",
        mailSentFailed: "メール送信に失敗しました",
        alreadySentParticipationMail: "参加メールを送信済み",
        alreadySentInviationMail: "招待メールは送信済みです。",
      },
      join: {
        invalidCodeorMail: "無効なコードもしくはメールが受信されない",
        invalidCode: "無効なコード",
        enterInvitationCode: "招待コードを入力して下さい",
      },
      bank: {
        accountHolderName: "振込先を入力してください",
        accountNumber: "口座番号を入力して下さい。",
        ifscCode: "ifscコードを入力して下さい",
        bankName: "銀行名を入力して下さい。",
        address: "住所を入力して下さい",
      },
      shipping: {
        blockNo: "マンション・ビル名を入力して下さい",
        address: "住所を入力して下さい",
        streetName: "住所を入力してください",
        city: "市区町村を入力して下さい",
        state: "都道府県を入力して下さい",
        country: "国を入力して下さい",
        company: "会社名を入力してください",
      },
      emptyField: "必須項目に記入して下さい",
      emptyFields: "必須項目に記入して下さい",
    },
    timeOut: {
      sorry: "申し訳ございません。",
      somethingWrong: "何かがうまくいかなかった",
      tryAgain: "再度試して下さい",
    },
    pageNotFound: {
      oops: "残念！",
      sorryPageNotFound:
        "申し訳ありませんが、お探しのページが見つかりませんでした",
      goToHome: "ホームページへ",
    },
    paymentAgreement: {
      fourModal: {
        paymentAgreementInEn: `All matters in question shall be investigated by Bidder prior to
              purchase agreement, after which the transaction is final and
              irreversible, except in cases such as treatment in the diamond
              that has not been disclosed or doubts regarding the Origin (
              Natural / Laboratory grown / Coating etc. ) of the diamond.`,
        paymentAgreementInJp: `すべての懸念事項は、購入契約の前に入札者が調査するものとします。その後、取引は最終的で不可逆的に成立するものとします。ただし、開示されていないダイヤモンドの処理（人工ダイヤ/コーティングなど）に関する疑いがある場合を除きます
              。`,
        paymentAgreementInZh: `投标人应在达成购买协议之前看清所有相关事宜，交易后不可撤销，除未披露钻石的处理方式或对原产地（天然/实验室培育/涂层等）有疑问
              的钻石。`,
      },
      secondModal: {
        paymentAgreementInEn: `Payment for the generated invoice is due within 3 business days of
              notice via bank Wire. Failure to submit payment by the due date
              will result in a late fee of 1% and could result in additional
              handling fees.`,
        paymentAgreementInJp: `オークション終了後に作成された請求書の支払い期限は、
              銀行振込による通知から 3
              営業日以内です。期日までに支払いを提出しないと、1%
              の遅延料金が発生し、追加の手数料が発生する可能性があります。`,
        paymentAgreementInZh: `发付款应在收到发票通知后 3
              个工作日内通过银行电汇支付。未能在到期日之前付款将导致 1%
              的罚金，并可能导致额外的手续费。`,
      },
      thirdModal: {
        paymentAgreementInEn: `Freight charges, custom charges and any other additional costs are
              born by bidder. Any discrepancies between the merchandise listed
              on the invoice and the actual merchandise received must be
              reported to within 24 hours of receipt.`,
        paymentAgreementInJp: `実際に御受け取りいただいた商品が請求書に記載の商品と異なる場合にはお受け取りから24時間以内に必ずご連絡ください。
        `,
        paymentAgreementInZh: `运费、海关费用和任何其他额外费用由投标人承担。发票上列出的商品与收到的实际商品之间的任何差异必须在收到后
              24 小时内报告。`,
      },
      firstModal: {
        paymentAgreementInEn: `Please be advised that a buyer commission of 3% will be applicable on the final purchase invoice.`,
        paymentAgreementInJp: `最終的な購入請求書には3％のバイヤー手数料がかかることをご了承ください。`,
        paymentAgreementInZh: `请注意，最终购买发票将收取 3% 的买家佣金。`,
      },
    },
    ongoing: "開催中",
    paused: "保留",
    break: "休憩",
    willResume: "~に戻ってきます",
    abouttoStart: "始めようとしています",
    invalidRequest: "無効なリクエスト",
    restrictToJoinAuction:
      "オークションに参加するには、パソコンの時計を自動モードに設定してください。",
    warning: {
      firstLine: "ご注意！",
      secondLine: "パソコンの時計を自動モードに設定してください",
    },
    somethingWentWrong: " エラー",
    kycFrom: {
      kycFromHeading: "KYCフォーム",
      name: "姓名",
      fName: "名",
      lName: "姓",
      line: "ライン",
      whatsup: "Whatsapp",
      weChat: "WeChat",
      sms: "SMS",
      facebook: "FB",
      swiftNo: "スイフト番号",
      modeOfCommunication: "連絡モード",
      japan: "日本",
      other: "他",
      knowKobustuLicense: "古物商許可証はお持ちですか",
      kobustuLicenceQuestion:
        "古物商許可証をお持ちですか／もしくは日本の関連会社がお持ちですか？",
      guaranterCompany: "保証人明細",
      companyDetail: "会社詳細",
      contactPerson: "担当者名",
      attachFiles: "必要書類",
      kobustuLicense: " 古物商許可証",
      uploadFiles: "ファイルアップロード",
      indenfyCertificate:
        "写真付きの身分証明書 （パスポート;エイリアンカード;運転免許証;マイナンバーカード）",
      passbookCopy: "指定口座の通帳のコピー",
      tokiboTohon: "会社登記簿謄本コピー",
      shippingAddressCapitalize: "配送先",
      billingAddress: "請求先",
      submit: "登録",
      yes: "はい",
      no: "いえ",
      bankDetails: "指定金融機関",
      blockNumber: "マンション・ビル名",
      streetName: "住所",
      city: "市区町村",
      state: "都道府県",
      subDomain: "サブドメイン",
      samePersonalDetail: "個人詳細と同じ",
      sameBillingAddress: "請求先と同じ",
      preferredLanuage: "優先言語",
      preferredCurrency: "ご希望の通貨",
      currency: "通貨",
      companyLogo: "会社のロゴ",
      document: "書類",
      shouldBeLess: "最大10mb",
      kycModal: {
        firstLine: "フォームを送信してくれてありがとうございます",
        secondLine: "弊社が間もなくご連絡いたします",
      },
      natureofBusiness: "ビジネスの本質",
      businessRegistration: {
        headingName: "ビジネスの詳細",
        heading:
          "ビジネスの登録ナンバー／会社設立証明書、もしくはご自身がこのビジネスに就いていると証明できるAライセンス等",
        dateOfIncorporation: "設立日時",
        iCNumber: "法人番号",
      },
      saveAsDraft: "保留",
      companyDoc: "会社登記簿謄本",
      bankDoc: "銀行情報(銀行情報が確認できる物：例：通帳カバーページ等)",
      bankDocHeading: "Bank Document",

      errorMessage: {
        onFName: "名を記入してください。",
        onLName: "姓を記入してください。",
        onCompanyName: "会社名を記入してください。",
        onShippingAdd: "配送先を入力してください",
        onBillingAddress: "請求先を記入してください。",
        onBankName: "銀行名を記入してください。",
        onBankAddress: "銀行住所を記入してください。",
        onAccHolderName: "振込先を入力してください。",
        onSwiftNo: "番号を入れてください",
        onAccountNo: "口座番号を記入してください。",
        onCountry: "国を記入してください。",
        onKobustuLic: "古物商許可証はお持ちですか",
        onPreferredLanguage: "優先言語を選択してください",
        onPreferredCurrency: "ご希望の通貨を選んでください",
        onModeOfComm: "連絡モードを選択してください",
        onContactPerson: "担当者名を記入してください",
        onKobustuLicense: "古物商許可証を添付してください",
        onTokiboTohon: "会社登記簿謄本コピーを添付してください",
        onPassbookCopy: "貯金通帳コピーを添付してください",
        onIdentityCertificate: "身分証明書を添付してください",
        onCompanyLogo: "会社のロゴを添付してください",
        onSelectKobustuLicense: "古物商許可証はお持ちですか？",
        onGuarantorCompanyName: "保証会社名を入力してください。",
        onGuarantorContactPerson: "保証会社の担当者名を入力してください。",
        onGuarantorEmail: "保証会社の正しいメールIDを入力してください。",
        onGuarantorContactNumber:
          "保証会社の正しい電話番号を入力してください。",
        onGuarantorMobileNumber: "保証会社の正しい電話番号を入力してください。",
        onGuarantorBillingAddress: "保証会社の請求先住所を入力してください。",
        onGuarantorShippingAdd: "保証会社の送付先住所を入力してください。",
        onGuarantorCountry: "保証会社の国名を入力してください。",
        onGuarantorAccHolderName: "保証会社の口座名義人を入力してください。",
        onGuarantorAccountNo: "保証会社の銀行口座番号を入力してください。",
        onGuarantorBankName: "保証会社の金融機関名を入力してください。",
        onGuarantorBankAddress: "保証会社の金融機関の住所を入力してください。",
        onReferenceCompanyName: "確認できる会社名をご記入ください",
        onReferenceContactPerson: "確認できるご本人のお名前をご記入ください",
        onReferenceEmail: "正しくメールアドレスをご記入ください",
        onReferenceContactNumber: "正確な連絡先をご記入ください",
        onReferenceMobileNumber: "正確な連絡先をご記入ください",
        onReferenceBillingAddress: "請求書の送り先の住所をご記入ください",
        onReferenceShippingAdd: "商品送り先の住所をご記入ください",
        onReferenceCountry: "国をご記入ください",
        onReferenceAccHolderName: "Please fill Reference account holder name",
        onReferenceAccountNo: "Please fill Reference account number",
        onReferenceBankName: "Please fill Reference bank name",
        onReferenceNatureOfBusiness: "お仕事をご記入ください",
        onReferenceDORC: "登録の日時／証明をご記入ください",
        onReferenceNOYB: "仕事勤続年数をご記入ください",
        onICN: "Please enter Incorporation Certificate Number",
        onBankDocument: "Please upload Bank Document",
        onCompanyDocument: "Please upload Company Document",
        saveAsDraft: "Your form has been submitted as draft.",
      },
      previous: "前回",
      next: "次",
      faq: {
        heading: "FAQ",
        faq: "よくある質問",
        myAuction: {
          question: "My Auctions は何ですか。",
          answer:
            "My Auctions はレンタルウエブサイトとして競売人は会員登録し自身のオークションを作成、開催が可能です。また、入札者は希望するオークションにも参加する事が可能です。",
        },
        privacyInsured: {
          question: "プライバシー保険は？",
          answer: {
            firstLine:
              "My Auctions は売主様のお品物を可能な限り最良の方法で買主様にご紹介し、お品物の状態、仕様について買主様が完全な透明性をもった情報開示を受けることができるようにするという目的のみのために作られたプラットフォームです。",
            secondLine:
              "そのような活動の一部として、第三者のオンライン広告主やウェブサイトやアプリと個人情報が共有されることはありません。My Auctionsは、そのような ウェブサイト運営者やネットワーク広告主に対して、あなたの利用に関する情報を提供しません。",
          },
        },
        whoIsAuctioneer: {
          question: "競売人／オクショーン主催者というのは誰か。",
          answer:
            "ダイヤや宝石袋など、高級な商品を売りたがる人は誰でも競売人・オクショーン主催者です。少なくとも10個の商品もいいし、100000以上もいい。オークションを主催しましょう！",
        },
        whoIsBidder: {
          question: "入札者というのは誰か。",
          answer:
            "ダイヤモンドや宝石バッグなどの商品を買いたい（オークションに）と思う人は、入札者です。",
        },
        howAuctioneerCreateAuctionWebsite: {
          question: "自分オクショーンウェブサイトはどうやってつくりますか。",
          answer: {
            firstLine:
              "競売人が名前付きか無名でオークションを開催する事が出来ます。例えば",
            points: {
              firstPoint:
                "1. サブドメインでのオークションを開催する。例えば: www.abcauction.myauctions.jp ",
              secondPoint:
                "2. 無名でオークションを開催する。例えば： www.myauctions.jp",
            },
            secondLine: "（それに応じて課される料金は変更されます。）",
            thirdLine: "サブドメインの確認は登録の時に行われた。 ",
          },
        },
        documentRequiredForRegisteration: {
          question: "必要な書類は？",
          answer: {
            firstLine:
              "日本でのオークションの実施・参加には、以下の書類が必要です。",
            points: {
              firstPoint: "1. 古物商許可証",
              secondPoint: "2. 会社登録書類",
              thirdPoint: "3. 銀行財務諸表/貯金通帳コピー",
              fourthPoint:
                "4. 写真付きの身分証明書 （パスポート;エイリアンカード;運転免許証;マイナンバーカード）",
            },
            secondLine:
              "オフショアバイヤー（日本国外の会社）は、日本で古物商許可証を持っている保証社が必要です。古物商許可証を持っている日本の会社のみが申請できます。",
            thirdLine:
              "商品に関する請求は、海外の購入者から提出された保証人の会社詳細に基づいて行われます。取引成立後の輸出等の手続きは、マイオークションまたは競売人の責任の範囲外となります。",
            fourthLine:
              "マイオークションは出品前に会社を徹底的に検証しますが、それぞれの競売人におきましても、徹底的な検証をお願いいたします。",
          },
        },
        returnToWebsite: "ウェブサイトに戻る",
      },
    },
    blockUserPopup: {
      firstLine: "アカウントが制限されています",
      secondLine:
        "MyAuctionsにお問い合わせください。メール：contact@myauctions.jp",
    },
    paymentForm: {
      securePaymentGateway: "お支払いは安全",
      payNow: "支払う",
      amountToPay: "お支払金額",
      paymentSuccessful: "お支払いは完了しました。",
      paymentSuccessfullMessage: "後ほどログイン情報を送ります。",
      paymentFailed: "支払不能",
      paymentFailedMessage:
        '"カードを確認し、もう一度やり直してください。又はマイオークションズにお問い合わせください．"',
    },
    aboutUs: {
      newPage: {
        meetTeam: "チームの紹介",
        heading: "お客様のご満足で、",
        heading1: "なり立っております。心からの問題解決の努力を惜しみません。",
        subheading: "一貫性と忍耐",
      },
      founder: {
        name: "モニカ　ジェイン",
        title: "設立者",
        founderAndCEO: "設立者　CEO モニカ　ジェイン",
        founderFirstText:
          "モニカ　ジェインは日本で活躍する事業家であり、エンジニアでもあるインド人女性。ジェインは、World Economic Forum(世界経済フォーラム)のグローバルシェイパーに選ばれている。",
        founderSecondText:
          "今日、ジェインは彼女の知識、ネットワーク、問題解決スキルを駆使して多くのスタートアップに協力。",
        founderThirdText:
          "ワーキングマザーのサポートの為、Shelovestech,Google for Startups,500Global,AWS Startups　と仕事をし、㈱MJ Diamonds、GPB Infraprojects Pvt Ltd(インド)の取締役会も務める。東京本社のMy　Auctionsは、彼女にとって４番目の起業になる。",
      },
      company: {
        companyProfie: "会社概要",
        tradeLabel: "会社名",
        tradeValue: "マイオークションズ",
        HeaderQuaLabel: "本社住所",
        HeaderValue: "1100005 東京都台東区上野ファーストビル3-10-1 201",
        EstablishedLabel: "設立",
        EstablishedValue: "令和2年8月5日",
        CEOLable: "CEO",
        CEOValue: "モニカ ジェイン",
        mainBusinessLabel: "主な事業",
        mainBusinessValue:
          "オークションシステムの開発，構築，販売並びにレンタル",
        corporateLabel: "法人番号",
        corporateValue: "0105-01-046069",
        bankLabel: "取引銀行",
        bankValue: "朝日信金銀行",
      },
      mission: {
        missionLabel: "ミッション ",
        missionValue:
          "全ての人達が簡単に、かつ公平にアクセスできるオークションを目標にしています．",
      },
      coFounders: {
        coFounder: "共同設立者",
        kanishk: {
          firstLine: "カニシュク　チョーラリア",
          secondLine: "Mr. Foot",
          thirdLine:
            "12年以上のIT業界の経験を持ち、InterConではtech leaders のTOP50にノミネートされる。持ち前の機敏さ優秀さで常に業界に革新をもたらす存在である。",
        },
        pradeep: {
          firstLine: "プラディープ　シャルマ",
          secondLine: "CTO",
          thirdLine:
            "プラディープ　シャルマは、20年間テクニカル専門に携わる経歴を持つシニアソフトウェアエンジニア。テクニカルリード、ITプロジェクトマネジャーとして働き、My Auctionsでは、サイバーセキュリティとウエブサイトインターフェースを担当",
        },
        haruka: {
          firstLine: "HARUKA ARAI",
          secondLine: "COO at Zipang Auctions, Japan Pawn Auction",
          thirdLine:
            "宝飾業界に40年以上従事し、現役を引退し３年の充電期間を経て2023年度よりZipang Auction のCOOに就任。",
        },
        shobhit: {
          firstLine: "  ショービット　スーチャンティ",
          secondLine: "Mr. soul",
          thirdLine:
            "ショービット　スチャンテイは、GIAダイヤモンドの資格を持つ貿易商。スーチャンティは、10年に及び日本のオークション界に携わる。今や日本のダイヤモンド輸出入に精通。現在は、My Auctionsのドメインエキスパート。",
        },
      },
    },
    mjDiamonds: {
      banner: {
        companyName: "MJ Diamonds",
        companyDescription: "ダイヤモンドの賢い買い方",
        button: "入札",
      },
      companyInfo: {
        about: "ストーリー",
        companyDescription:
          "高級ダイヤモンド・宝飾の卸売会社です。 最高の価値、高品質、幅広い種類のダイヤモンドのセレクションをお客様に提供することをお約束します。 ダイヤモンド・宝飾すべてのニーズは、このサイトで見つけることが出来ます。我社の目標はこの最新購買方法で貴社のビジネスを更に効率的に拡大し貢献することです。",
      },
      daimodsInfo: {
        daimond: "ダイヤモンド",
        auction: "オークション",
        jewellery: "宝石",
      },
      auctionInfo: {
        auction: "オークション",
        importantDaimond: "ダイヤルース＆宝石",
        auctionDescription:
          "ネットワークを通じ様々な高品質のアイテムを購入できる、日本を代表するオークション。 国内、海外と多種多様なダイヤモンドを豊富に取り揃えたオークションをご用意しております。 ",
      },
      relevantInfo: {
        meetRelevant: "適切な人達に出会う",
        relevantDescription:
          "Descriptions of the people working together for MJ Diamonds can re represented here. They can provide multiple images that will be visible (as appearing in left side) in the form of a collage.",
      },
    },
    subDomain: {
      banner: {
        companyName: "貴社のお名前",
        companyDescription:
          "おおよそ２０～３０字で貴社の紹介やプロフィールがここにアップデートされます。",
        button: "入札開始",
      },
      daimodsInfo: {
        watches: "時計",
        daimond: "ダイヤモンド",
        auction: "オークション",
        jewellery: "宝石類",
      },
      companyInfo: {
        about: "～について",
        companyDescription:
          "オークションに出品される物の記載が陳列されます。リサイクルダイヤモンド、メレ、時計などが可能です。多くのカテゴリーのダイヤモンドがある為、全てに100文字以内の記載を付けることができます。例）リサイクルダイヤモンド、メレ、時計，宝石等。",
      },
      auctionInfo: {
        auction: "オークション",
        importantDaimond: "オークション商品",
        auctionDescription:
          "オークションに出品される物の記載が陳列されます。リサイクルダイヤモンド、メレ、時計などが可能です。多くのカテゴリーのダイヤモンドがある為、全てに100文字以内の記載を付けることができます。例）リサイクルダイヤモンド、メレ、時計，宝石等。",
      },
      relevantInfo: {
        meetRelevant: "適切な人達に出会う",
        relevantDescription:
          "abcダイヤモンドで共に働く人々についての記載が、こちらに記載されます。(左側に)コラージュフォームされた写真を見ることができます。",
      },
    },
    okura: {
      banner: {
        companyName: "株式会社おお蔵",
        companyDescription: "「世界を、リユースで楽しくする。」",
        button: "入札開始",
      },
      daimodsInfo: {
        watches: "時計",
        daimond: "ダイヤモンド",
        auction: "オークション",
        jewellery: "宝石類",
      },
      companyInfo: {
        about: "ストーリー",
        companyDescription:
          "国内外における時計、バック、貴金属等古物品の卸・販売業/国際宝飾品などの催事販売/オークション事業を行っております。",
      },
      auctionInfo: {
        auction: "オークション",
        importantDaimond: "オークション商品",
        auctionDescription:
          "ブランド時計、ブランドジュエリー､ノンブランドジュエリー、ルースダイヤモンドをメインﾆ出品しております。 ブランドジュエリー Cartier, Van Cleef & Arpels, Tiffany, Bvlgari等。ブランド時計に関してはRolex, Omega 等。",
      },
      relevantInfo: {
        meetRelevant: "適切な人達に出会う",
        relevantDescription:
          "株式会社おお蔵　設立2019年7月1日　代表取締役　三城将也　事業所〒812-0893 福岡県福岡市博多区那珂4-20-33　古物営業法許可証：福岡県公安委員会 古物商許可番号 第901021910081号　福岡県公安委員会 古物市場主許可番号 第901011920001号　",
      },
      footer: {
        company: "MY AUCTIONS",
        copyright: "2020-21 All Rights Reserved. ",
        officeNumber: "O: 03-5829-9622",
        faxNumber: "Fax: 03-5829-9652",
        officeAddress:
          "〒101-0032 東京都千代田区岩本町2-4-10 小田急神田岩本町ビル3F,4F",
        emailId: "-",
      },
    },
    nihonauction: {
      banner: {
        companyName: "日本オークション",
        companyDescription: "ダイヤモンドの賢い買い方",
        button: "入札",
      },
      companyInfo: {
        about: "ストーリー",
        companyDescription:
          "高級ダイヤモンド・宝飾の卸売会社です。 最高の価値、高品質、幅広い種類のダイヤモンドのセレクションをお客様に提供することをお約束します。 ダイヤモンド・宝飾すべてのニーズは、このサイトで見つけることが出来ます。我社の目標はこの最新購買方法で貴社のビジネスを更に効率的に拡大し貢献することです。",
      },
      daimodsInfo: {
        daimond: "ダイヤモンド",
        auction: "オークション",
        jewellery: "宝石",
      },
      auctionInfo: {
        auction: "オークション",
        importantDaimond: "ダイヤルース＆宝石",
        auctionDescription:
          "ネットワークを通じ様々な高品質のアイテムを購入できる、日本を代表するオークション。 国内、海外と多種多様なダイヤモンドを豊富に取り揃えたオークションをご用意しております。 ",
      },
      relevantInfo: {
        meetRelevant: "適切な人達に出会う",
        relevantDescription:
          "Descriptions of the people working together for MJ Diamonds can re represented here. They can provide multiple images that will be visible (as appearing in left side) in the form of a collage.",
      },
    },
    Tender: {
      tableColumnNames: {
        serialNo: "S. No.",
        productId: "Product Id",
        Itemcode: "Item Code",
        sku: "SKU",
        lab: "LAB",
        certification: "Certification",
        description: "Description",
        pricePct: "Price Pct",
        jewelleryPrice: "Starting Price",
        yourBiddingPrice: "あなたの下見の際の価格",
        notes: "Notes",
        liveHighestBid: "Live Highest Bid",
        rapOff: "Rap Off",
        noOfBidders: "No Of Bidders",
        timeLeft: "残りの時間",
        remark: "Remarks",
        startingPrice: "Starting Price",
        Sieve: "Sieve",
        ShowAll: "すべて表示",
        Doyouwishtobid: "入札希望しますか",
        ActionsTender: "Action",
        carat: "Carat",
        color: "Color",
        clarity: "Clarity",
        shape: "Shape",
        cutGrade: "Cut Grade",
        noOfPieces: "No Of Pieces",
        polish: "Polish",
        symmetryAbbreviation: "Symmetry",
        flour: "Flour",
        yourBid: "Your Bid :",
        bulkBid: "一括入札",
        details: "詳細",
        yourBidPrice: "あなたの入札価格",
        placeBid: "Place Bid",
      },
      rapOff: "ラップオフ",
      validation: {
        productSelection: "１度に、商品10品だけ選んでください",
      },
      bidInfo: {
        bidDescription: "入札希望しますか",
      },
      button: {
        showAll: "全てを見せる",
        placeBid: "入札",
      },
    },
    selby: {
      banner: {
        companyName: "セルビー",
        companyDescription: "ダイヤモンドの賢い買い方",
        button: "入札",
      },
      companyInfo: {
        about: "ストーリー",
        companyDescription:
          "高級ダイヤモンド・宝飾の卸売会社です。 最高の価値、高品質、幅広い種類のダイヤモンドのセレクションをお客様に提供することをお約束します。 ダイヤモンド・宝飾すべてのニーズは、このサイトで見つけることが出来ます。我社の目標はこの最新購買方法で貴社のビジネスを更に効率的に拡大し貢献することです。",
      },
      daimodsInfo: {
        daimond: "ダイヤモンド",
        auction: "オークション",
        jewellery: "宝石",
      },
      auctionInfo: {
        auction: "オークション",
        importantDaimond: "ダイヤルース＆宝石",
        auctionDescription:
          "ネットワークを通じ様々な高品質のアイテムを購入できる、日本を代表するオークション。 国内、海外と多種多様なダイヤモンドを豊富に取り揃えたオークションをご用意しております。 ",
      },
      relevantInfo: {
        meetRelevant: "適切な人達に出会う",
        relevantDescription:
          "Descriptions of the people working together for MJ Diamonds can re represented here. They can provide multiple images that will be visible (as appearing in left side) in the form of a collage.",
      },
    },
    dialuster: {
      banner: {
        companyName: "Dialuster",
        companyDescription: "Nothing is impossible",
        button: "入札",
      },
      companyInfo: {
        about: "ストーリー",
        companyDescription:
          "高級ダイヤモンド・宝飾の卸売会社です。 最高の価値、高品質、幅広い種類のダイヤモンドのセレクションをお客様に提供することをお約束します。 ダイヤモンド・宝飾すべてのニーズは、このサイトで見つけることが出来ます。我社の目標はこの最新購買方法で貴社のビジネスを更に効率的に拡大し貢献することです。",
      },
      daimodsInfo: {
        daimond: "ダイヤモンド",
        auction: "オークション",
        jewellery: "宝石",
      },
      auctionInfo: {
        auction: "オークション",
        importantDaimond: "ダイヤルース＆宝石",
        auctionDescription:
          "直人	正守の指導の下、最近、日本から世界に至るまでのダイヤモンドとジュエリーの最大の種類の印象的なラインナップでオークションを開始しました。 ",
      },
      relevantInfo: {
        meetRelevant: "適切な人達に出会う",
        relevantDescription:
          "Descriptions of the people working together for MJ Diamonds can re represented here. They can provide multiple images that will be visible (as appearing in left side) in the form of a collage.",
      },
      footer: {
        company: "MY AUCTIONS",
        copyright: "2020-21 All Rights Reserved. ",
        officeNumber: "O: 03-6421-8885",
        faxNumber: "Fax: 055-223-0211 ",
        officeAddress:
          "東京都台東区上野5-16-17 千代田ビル2F ​株式会社ディアラスター",
        emailId: "dialuster@gmail.com",
      },
    },
    kiraga: {
      banner: {
        companyName: "ジュエリーメーカー KIRAGA",
        companyDescription: "ダイヤモンドの賢い買い方",
        companyDescriptionNew:
          "静岡県駿東郡にある宝飾品メーカーです。宝飾品の製造、加工、卸販売、小売販売を行っています。クレマチスの丘の上にあるサロンにも一度遊びに来てください。展示会も行っております。",
        button: "入札",
      },
      companyInfo: {
        about: "ストーリー",
        companyDescription:
          "静岡県駿東郡の宝石メーカーで宝飾品の製造、加工、全国卸売り販売、および小売り販売をしております。",
      },
      daimodsInfo: {
        daimond: "ダイヤモンド",
        auction: "オークション",
        jewellery: "宝石",
      },
      auctionInfo: {
        auction: "オークション",
        importantDaimond: "宝飾品リング、ペンダント、ピアス等",
        auctionDescription:
          "ネットワークを通じ様々な高品質のアイテムを購入できる、日本を代表するオークション。 国内、海外と多種多様なダイヤモンドを豊富に取り揃えたオークションをご用意しております。 ",
      },
      relevantInfo: {
        meetRelevant: "適切な人達に出会う",
        relevantDescription:
          "こだわりのジュエリーと開放的な庭、そして熟練の職人とお客様の思いを形にするスタッフがご来店をお待ちしています。宝石店と聞くと少し入りにくいイメージがあるかもしれませんが、本当に気軽にふらっと遊びに来てください。",
        relevantDescriptionAdd: "",
      },
      footer: {
        company: "MY AUCTIONS",
        copyright: "2020-21 All Rights Reserved. ",
        officeNumber: "O: 055-980-1888",
        faxNumber: "Fax: - ",
        officeAddress: "株式会社キラガ 静岡県駿東郡長泉町東野608番地の121",
        emailId: "",
      },
      releventHeader: {
        relevantHeader: "ジュエリーメーカ主催の格安オークションです",
        firstdescription: "招待制オークションとなります、参加希望の方は ",
        seconddescription: "TwitterのDMもしくはe-shop@kiraga.co.jp まで",
        thirdDescription:
          "ご連絡ください。オークションの開催予定はTwitterまたはInstagramで告知しております。",
        descriptionAdd:
          "落札時は落札価格に加えて消費税が必要となりますので ご了承ください。",
      },
    },
    takeyama: {
      banner: {
        companyName: "株式会社武山",
        companyDescription:
          "1947年より東京御徒町でダイヤモンド、色石　ジュエリーの製造をしています。",
        button: "入札",
      },
      companyInfo: {
        about: "ストーリー",
        companyDescription:
          "1947年より東京御徒町でダイヤモンド、色石　ジュエリーの製造をしています。",
      },
      daimodsInfo: {
        daimond: "ダイヤモンド",
        auction: "オークション",
        jewellery: "宝石",
      },
      auctionInfo: {
        auction: "オークション",
        importantDaimond: "宝飾品リング、ペンダント、ピアス等",
        auctionDescription:
          "ネットワークを通じ様々な高品質のアイテムを購入できる、日本を代表するオークション。 国内、海外と多種多様なダイヤモンドを豊富に取り揃えたオークションをご用意しております。 ",
      },
      relevantInfo: {
        meetRelevant: "適切な人達に出会う",
        relevantDescription:
          "Descriptions of the people working together for MJ Diamonds can re represented here. They can provide multiple images that will be visible (as appearing in left side) in the form of a collage.",
      },
      footer: {
        company: "MY AUCTIONS",
        copyright: "2020-21 All Rights Reserved. ",
        officeNumber: "O: 03-3834-5050",
        faxNumber: "Fax: - ",
        officeAddress: "東京都台東区東上野1丁目-17-３",
        emailId: "",
      },
    },
    inquiry: {
      support: "お問い合わせ",
      Inquiry: "お問い合わせ",
      button: {
        submit: "登録",
        Reply: "返事",
        bidNow: "今、入札",
      },
      submitQueriy: "提出",
      submitDescription: "お問い合わせをお願い致します。",
      singleProduct: "お問い合わせをお願い致します。",
      typeMessege: "メッセージを入力してください",
      placeYourQuery: "こちらにお問い合わせをお願い致します。",
      fields: {
        Section: "セクション",
        Status: "状態",
        Action: "アクション",
        Pending: "保留中",
        Replied: "返信しました",
        Resolved: "解決済み",
        Product: "製品",
        Other: "他",
        Query: "お問い合わせ",
        Attachment: "添付ファイル",
        AttachmentLink: "ATTACHMENT LINK",
        ChooseFile: "添付ファイル",
      },
      toastString: {
        messageSent: "送信に成功しました",
      },
      popupString: "24時間以内に返信します",
      popupStringFirst: "お問い合わせを確認しました",
      inquiryText:
        "マイオークションのお問い合わせスペースへようこそ。ご不明な点がございましたら、チャットでお問い合わせください。",
    },
    toastTraslation: {
      ParticipationMailAlreadySent: "参加メールはすでに送られています",
      AddedIntoWatchlistsuccessfully: "お気に入りに入りに入れました",
      Removedfromwatchlistsuccessfully: "お気に入りから削除されました",
      Notesaved: "備考が保存されました",
      PrebidPriceSavedSuccessfully: "前入札が保存されました",
      PreBidPriceRemovedsuccessfully: "前入札が削除されました",
      Operationnotallowed: "操作は許可されていません",
      messageSent: "送信に成功しました",
      replyText: "Please Enter Message",
    },
    hokuyu: {
      banner: {
        companyName: "北友",
        companyDescription: "日本からに買取宝石",
        button: "入札",
      },
      companyInfo: {
        about: "ストーリー",
        companyDescription:
          "高級宝石・宝飾の卸売会社です。 最高の価値、高品質、幅広い種類の宝石のセレクションをお客様に提供することをお約束します。 宝石・宝飾すべてのニーズは、このサイトで見つけることが出来ます。我社の目標はこの最新購買方法で貴社のビジネスを更に効率的に拡大し貢献することです。",
      },
      daimodsInfo: {
        daimond: "ダイヤモンド",
        auction: "オークション",
        jewellery: "宝石",
      },
      auctionInfo: {
        auction: "オークション",
        importantDaimond: "宝石",
        auctionDescription:
          "ネットワークを通じ様々な高品質のアイテムを購入できる、日本を代表するオークション。 国内、海外と多種多様な宝石を豊富に取り揃えたオークションをご用意しております。",
      },
      relevantInfo: {
        meetRelevant: "適切な人達に出会う",
        relevantDescription:
          "Descriptions of the people working together for 北友 can re represented here. They can provide multiple images that will be visible (as appearing in left side) in the form of a collage.",
      },
    },
    network: {
      compeleteProfile: "プロフィールをご確認ください。",
      internetConnection:
        "ページを更新するか、インターネット接続を確認してください。",
    },
    FreePersonalizedWebsites: {
      text: {
        textfirst: "無料個人ウエブサイト",
        textsecond: "オンラインオークションはもはや過去のものではない",
        textSecondOne:
          "今日のオンラインオークションのエンドツーエンドのソリューションを入手してください！",
        third: "もし次のレベルに進みたい場合は、わたくしたちは~",
        four: "あなたのビジネスを広げるためのプラットフォーム",
      },
      CreateWebSite: "あなたの無料ウエブサイトを作成",
      CreateWebSiteSecond: "無料オークションウェブサイト作成",
      sliderText: {
        textFirst:
          "あなたのオークションサイトのニーズに合ったプラットフォーム探し",
        textSecond: "あなたに決意させるより洗練されたワークフローを築いた",
        textThird:
          "MY AUCTIONSはあなた独自の無料ウエブサイト作成をお手伝いします",
      },
      websiteForm: {
        EnterDetails: "詳細を記入",
        Name: "名前",
        CompanyName: "会社名",
        Address: "住所",
        ChooseFile: "ファイル選択",
      },
    },
    pendingProduct: {
      HeaderName: {
        productId: "商品ID",
        biddingPrice: "入札価格",
        sellingPrice: "販売価格",
        description: "記述",
        negotiate: "交渉",
        auctionName: "オークション名",
      },
      button: {
        negotiate: "交渉",
        yes: "はい",
        no: "いいえ",
        cancel: "キャンセル",
        ok: "ok",
      },
      text: {
        DoYouWishNegotiatePopupFirst: "販売価格で商品を購入しますか",
        DoYouWishNegotiatePopupSecond: "",
        descriptionFirstText: "製品の交渉価格をで入力してください  ",
        descriptionSecondText: "  入札価格:   ",
        descriptionThird: " と販売価格は  ",
        enterNegotiateValue: "交渉価格を入力してください",
        enterNegotiateValuegreater:
          "交渉価格は入札価格よりも高くする必要があります",
      },
      modal: {
        winnerText: "Product Sold on Selling Price",
      },
    },
    newLandingPage: {
      signUp: {
        heading:
          "全てのオークションにアクセスして、無料で無限大のオークションをクリエイトしよう。",
        subHeading: "14日間のトライアルのスタート",
        buttonText: "無料トライアルのスタート",
        formHeading: "会員登録して入札を開始",
        formHeading2: "",
        errorMessage: "Please fill in the required information.",
      },
      otpText: "送信されたコードを入力してください",
    },
    newCommanTrans: {
      Seller: "出品者",
      Bidder: "入札者",
      Cart: "カート",
      Itemcode: "商品コード",
      Date: "日付",
      preBiddingprice: "前入札価格",
      productdescription: "商品説明",
      state: "状態",
      startingprice: "開始価格(PCT.)",
      sellingprice: "落札価格",
      finalbidprice: "最終落札価格",
      SKU: "SKU",
      totalAmount: "合計金額",
      rowsperpage: "1ページあたりの行数",
      hold: "保留中",
      sold: "落札",
      productID: "商品ID",
      notificationdate: "通知日",
      auctionname: "オークション名",
      notificationtype: "通知タイプ",
      description: "通知内容",
      notifications: "通知内容",
      soldproduct: "落札された商品",
      customMessage: "カスタムメッセージ",
      deleteProduct: "商品削除",
      holdProduct: "商品の保留",
      outBid: "アウトビッド",
      filters: "フィルター",
      all: "すべて",
      reset: "リセット",
      next: "次へ",
      selectAll: "すべて選択",
      serialNo: "番号",
      noOfBiddersBids: "入札者数/入札件数",
      remarks: "備考",
      replied: "回答済み",
      sorrynomatchingrecordsfound:
        "申し訳ございませんが、該当する記録はございませんでした。",
      diamondandParcel: "ダイヤモンド＆メル",
      jewellery: "ジュエリー",
      negotiate: "交渉する",
      calenderMonths: "",
      timeleft: "残り時間",
      home: "ホーム",
      preview: "下見",
      auction: "オークション",
      watchlist: "お気に入り",
      invoice: "請求書",
      profile: "プロフィール",
      product: "商品",
      other: "その他",
      totalAmounts: "合計金額",
      surelogout: "本当にログアウトしますか？",
      CANCEL: "キャンセル",
      OK: "OK",
      prductDaysauction: "365日オークション",
      productDaysAuctionInvoices: "365日オークション 請求書",
      givedetails: "詳細を入力する",
      getyourAuctionapproved: "オークションの承認",
      sellandEnjoy: "落札して楽しむ",
    },
    zipangLanding: {
      tab: {
        upcomingAuc: 'UPCOMING AUCTIONS',
        about: "～について",
        sell: '売る',
        buy: '買う',
        contact: 'コンタクト',
        ourTeam: 'OUR TEAM',
      },
      ourTeam: {
        ourTeamheading: 'Our Team',
        ourteamSubHeading: 'Empowering Bidding Brilliance Unveiled At Zipang',
        whyChooseus: 'WHY CHOOSE US?',
        seemLessHeading: 'Seamless Bidding: Where Satisfaction Meets Every Auction',
        team: {
          member1: {
            name: 'SHOBHIT SUCHANTI',
            title: 'President',
            description: "ショービット　スチャンテイは、GIAダイヤモンドの資格を持つ貿易商。スーチャンティは、10年に及び日本のオークション界に携わる。今や日本のダイヤモンド輸出入に精通。現在は、My Auctionsのドメインエキスパート。"
          },
          member2: {
            name: 'HARUKA ARAI',
            title: 'COO',
            description: "宝飾業界に40年以上従事し、現役を引退し３年の充電期間を経て2023年度よりZipang Auction のCOOに就任。"
          },
          member3: {
            name: 'SARTHAK JAIN',
            title: 'HK Office Correspondent',
            description: 'Sarthak Jainは、Zipang Auctions日本・香港オフィスのヘッドセールスマネージャーを務めています。ジェインの献身的な努力により、ジパングオークションのチームは、競争の激しい天然ダイヤモンドオークションの世界で成功を収めています。Jainは香港オフィスがシームレスに運営されるよう尽力している。'
          },
          member4: {
            name: 'YUKO OGINO',
            title: 'Tokyo Office Correspondent',
            description: '荻野裕子は、ジパングオークション日本支社で最も長く勤務している日本人コレスポンデントとして知られている。豊富な経験を生かし、顧客との強い信頼関係を築き、問い合わせに対応し、オークション用にあらゆる方向からダイヤモンドを丹念に撮影している。'
          },
        },
        seller: {
          tile1: {
            heading: '世界トップクラスのバイヤー',
            description: 'インド・中国・アメリカなど。当社の審査を通過し、その適格性を証明する強力なバイヤーが多数参加。およそ9000社のネットワーク。'
          },
          tile2: {
            heading: 'パソコンが苦手な人でも大丈夫。',
            description: '私達にお任せください。責任を持って大切な商品をお預かりいたします。あくまでも主導権はお客様の透明性あるオークション。'
          },
          tile3: {
            heading: '360°画像撮影',
            description: '360°撮影動画を世界に発信。世界中のバイヤーが貴社のアイテムをオンラインで見ることが可能。'
          },
          tile4: {
            heading: '海外に強い専門チームがサポート',
            description: '全てのお客様の個人情報を暗号化で保護。バイヤー登録の方には身分証、経営実績等の書類提出を求め身元の厳しい審査。'
          },
        },
        buyer: {
          tile1: {
            heading: '日本高品質の商品がラインアップ。',
            description: 'ダイヤモンド商人が厳選した高品質のダイヤモンドの品揃へアクセス。　'
          },
          tile2: {
            heading: 'プロフェッショナルサポートのチーム。　',
            description: '証明書や画像を超え、あなたの興味あるアイテムに関して全て回答する専門チーム。'
          },
          tile3: {
            heading: '快適に座りながら購入する。',
            description: 'ダイヤモンドの可能な限りの細部まで、360°撮影動画、付属しているルーペで見、貴重な時間を失うことなく、語学の壁を乗り越えて日本でダイヤモンドを購入することができます。'
          },
          tile4: {
            heading: '日本の質オークションをインターネット時代',
            description: '私たちの１０秒入札オークションは、エキサイティングな経験を提供するだけでなく、透明性と会計責任をレベルアップ致します。あなたのパドルを上げて、携帯電話よりオンラインで、石を勝ち取ってください！'
          },
        }
      },
      heading: {
        fistHeading: '',
        secHeading: '',
        subHading: '',
        upcomingAuctions: '',
        seller: '売主様',
        buyer: '入札者',
      },
      button: {
        sell: "売る",
        buy: '買う',
        reserveSlot: '下見のご予約はこちらから',
      },
      sellerTrans: {
        firstBox: {
          heading: '世界トップクラスのバイヤー',
          description: '外国語のスキルや直接交渉は必要ありません。当社のクライアントはすべて、当社の審査を通過し、その適格性を証明する強力なリファレンスを提供した国際取引の承認メンバーです。',
        },
        secondBox: {
          heading: '信頼性',
          description: '専門家チームが鑑定し、証明書や画像だけでなく、お客様のご興味のある商品に関するあらゆる懸念にお答えします！',
        },
        thirdBox: {
          heading: '透明性',
          description: 'オークションの最終的な主導権はお客様にあります。マイページからお客様の石を確認し、石の詳細な情報を得ることができます。',
        },
        GiveDetails: {
          title: '1. 詳細をお知らせください',
          heading: 'メール/whatsapp/line/wechatで提出したい石のリストをcsvで添付して提出してください！',
          subHeading: '',
        },
        sendGoods: {
          title: '2. 商品を送る',
          heading: 'シートに添付された商品を弊社の住所までお送りください！',
          subHeading: '日本 110-0016 東京都台東区台東4丁目10番7号安井ビル3階',
          thirdSubHeading: '香港 11 5F Block A Focal Industrial Centre 21 Man Lok St, Hung Hom, Hong Kong',
        },
        sellorEnjoy: {
          title: '3. 売る＆楽しむ',
          heading: '私たちの専門知識、技術、そしてコネクションを駆使して、,ダイヤモンズを販売されるお客様に絶対的な価値をご提供いたします。',
          subHeading: '',
        }
      },
      buyerTrans: {
        firstBox: {
          heading: '商品ラインナップ',
          description: '厳選された買取屋の豊富な商品を、パソコンやモバイルでご覧いただけます。私たちの究極のモットーは、良いものだけをお届けすることです！',
        },
        secondBox: {
          heading: 'テクノロジー',
          description: '貴重な時間や旅費をロスすることなく、日本でダイヤモンドを購入することができます。ダイヤモンドは、360度動画、ルーペ付き画像、専門家によるガイダンスなど、可能な限り細部にまでこだわっています。',
        },
        thirdBox: {
          heading: '透明性と信頼性',
          description: '鑑定書や画像だけでなく、お客様がご興味をお持ちの品目に関するあらゆるご質問にお答えする専門家チームです！',
        },
      },
      registerTitle: '登録する。 認証を受ける。 全てを買う！！',
      footer: {
        japanAddress: "",
        honkongAddress: "",
        clientDetails: "",
        contactSection: {
          email: '',
          TermsandService: '',
          companyDetails: ''
        }
      }
    }
  },
  trans: {
    error: "Error",
    removeFromWatchList: "Removed from watchlist successfully",
    fieldSelect: "-- Select --",
    pleaseEnterFromWeight: "Please enter from weight",
    supportCaps: "SUPPORT",
    mazal: "MAZAL!!",
    endDateGreaterThanStartDate: "End date should be greater than start date",
    pleaseVerifyCaptchs: "Please verify captcha",
    productDescriptionError: "商品説明をお願いします。",
    subdomainAlreadyExists: "Subdomain already exits",
    saveAsDraft: "SAVE AS DRAFT",
    businessDetails: "Business Details",
    businessRegistrationNumber:
      "Business Registration Number / Incorporation Certificate ­OR A License or Evidence, which entitled you to run this Particular Business",
    dateOfRegAndCertificate: "Date Of Registration / Certificate",
    incorporateCertificate: "Incorporation Certificate",
    noOfYearsInBusiness: "No. of Years in Business",
    natureOfBusiness: "Nature of Business",
    kycReferenceCompany:
      "Reference company (Preferably My Auctions approved user)",
    kycReferenceDetails: "Reference details",
    kycOtherGroupCompany:
      "Other Group Company / Sister Concerns / Associates etc (non mandatory field)",
    payNow: "Pay Now",
    noteAddedSuccessfully: "Note added successfully",
    prebidPriceSavedSuccessfully: "Pre-bid price saved successfully",
    prebidPriceRemovedSuccessfully: "Pre-bid price removed successfully",
    addToWatchlistSuccessfully: "Added into watchlist successfully",
    removedFromWatchlistSuccessfully: "Removed from watchlist successfully",
    errorMessage: {
      operationNotAllowed: "Operation not allowed",
      tokenExpired: "Token Exired",
      notAllowedToAccess:
        "You are not allowed to access myauctions , Please subscribe for complete access.",
      dontHaveInvitationToAccess: "You don't have any invitation to access.",
      accessRestrictedContactUs:
        "We have restricted your access to MyAuctions.jp. Please contact MyAuctions or mail us at contact@myauctions.jp",
      paymentSucess: "Payment Success",
      otpExpired: "Otp Expired",
      unknownErrorServerSideException: "Unknown error from server",
      userPaymentFail: "error occurred while payment",
    },
  },
};