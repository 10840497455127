import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import Button from "../../common/elements/Button";
import { useTranslation } from "react-i18next";

const FilterHeading = (props: any) => {
  return <div className="fontBold">{props.children}</div>;
};

const priceList = [
  {
    name: "common:h2l",
    value: 0,
  },
  {
    name: "common:l2h",
    value: 1,
  },
];
export const itemsList = [
  {
    name: "common:jwlry",
    value: 0,
  },
  {
    name: "common:diamond",
    value: 1,
  },
];

const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: "2em",
  },
}));


const Filters = ({
  onChange,
  values,
  isZipang
}: {
  onChange: (price: any, item: any) => any;
  values: number[];
  isZipang: boolean;
}) => {
  const { t } = useTranslation();
  const [price, setPriceFilter] = useState("");
  const [items, setItemFilter] = useState("");
  const classes = useStyles();


  const handlePriceChange = (e: { target: { value: any } }) => {
    const val = e.target.value;
    setPriceFilter(val);
  };

  const handleItemsChange = (e: { target: { value: any } }) => {
    const val = e.target.value;
    setItemFilter(val);
  };

  const handleReset = () => {
    setPriceFilter("");
    setItemFilter("");
    onChange("", "");
  };

  const handleRefine = () => {
    onChange(price, items);
  };
  return (
    <Grid container item xs={false}>
      <Grid item xs={12} sm={4}>
        <FilterHeading>{t("common:filSrch")}</FilterHeading>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.margin}
            >
              <InputLabel id={"price-label"}>
                {t("common:preview:price")}
              </InputLabel>
              <Select
                id={"price"}
                label={t("common:preview:price")}
                name={"price"}
                value={price}
                onChange={handlePriceChange}
              >
                {priceList.map((filter) => (
                  <MenuItem key={filter.name} value={filter?.value}>
                    {t(filter?.name)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.margin}
            >
              <InputLabel id={"item-label"}>{t("common:items")}</InputLabel>
              <Select
                id={"item"}
                label={t("common:items")}
                name={"item"}
                value={items}
                onChange={handleItemsChange}
              >
                {itemsList.map((filter: any) => (
                  <MenuItem key={filter.name} value={t(filter?.name)}>
                    {t(filter?.name)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems="flex-end" item xs={12} sm={4}>
        <Grid container justifyContent="center" alignItems="center">
          <Button type="contained-black" onClick={handleReset}>
            {t("common:reset")}
          </Button>
          <Button type={isZipang ? "contained-dark-Red" : "contained-dark"} onClick={handleRefine}>
            {t("common:refine")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Filters;
