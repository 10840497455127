import React, { useEffect, useState } from "react";
import styled, { css } from 'styled-components';
import { Grid, Menu, MenuItem } from '@material-ui/core';
import { backgroundGradient, buttonGradient, dateBackgroundGradient } from "../../common/materials/LinearGradient";
import { StyledCardContainer } from "../../common/materials/GenericStyleComponent";
import EllipsisText from "../../common/elements/EllipsisText";
import ProductDescription from "../shared/ProductDescription";
import Countdown from "react-countdown";
import moment from "moment";

const StyledAuctionName = styled(Grid)`
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledTitleWrapper = styled(Grid)`
  background: ${backgroundGradient};
  padding: 1em;
  display: flex;
  align-items: center;
  height: 70px;
  font-size: 1em;
  color: ${(props) => props.theme.colors.base.white};
  
  .height {
    height: 100%;
  }
`;

const StyledProductImageContainer = styled(Grid)`
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  position: relative;
`;

const StyledProductImage = styled("img")`
  max-width: 100%;
  max-height: 100%;
`;

const StyledWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
  color: ${(props) => props.theme.colors.base.newgradientDarkMixBlueColor};
  text-align: center;
`;

const StyledAuctionNameWrapper = styled(Grid)`
  height: 100%;
  color: ${(props) => props.theme.colors.base.newgradientDarkMixBlueColor};
  text-align: center;
  font-size: 0.7em;
`;

const StyledLabelWrapper = styled.div`
    text-align: center;
    font-size: 0.8em;
`;

const StyledSecondContainer = styled(Grid)`
   height: 50px;
   display: flex;
   align-items: center;
`;

const StyledSerialNumberLabelWrapper = styled.div`
  text-align: center;
  font-size: 0.8em;
`;

const StyledTimeLeftLabelWrapper = styled.div`
  text-align: center;
  font-size: 0.6em;
  color: black;
`;

const StyledTimerSection = styled.div`
  text-align: center;
  font-size: 0.7em;
  ${(props) => props.theme.breakpoints.between("xs", "lg")} {
    font-size: 0.5em;
  }
`;

interface IPartcipateProps {
  isParticipate?: boolean;
}

const StyledParticipateButton = styled.div<IPartcipateProps>`
  cursor: pointer;
  color: ${(props) => props.theme.colors.base.white};
  background: ${backgroundGradient};
  border-radius: 2px;
  width: 90%;
  height: 40px;
  display: flex;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.isParticipate &&
    css`
      cursor: default;
      color: black;
      background: ${dateBackgroundGradient};
    `}
`;

const StyledParticipateButtonWrapper = styled(Grid)`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const StyledValueConatiner = styled.div`
    text-align: center;
`;

const StyledBidAmount = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const StyledContDaimond = styled.div`
  padding: 0.3em;
  font-size: 1.4em;
`;
const StyledContJwl = styled.div`
  padding: 0.2em;
  font-size: 1.2em;
`;
const StyledRemarks = styled(Grid)`
  display: flex;
`;

const StyledRemarkLbl = styled.div`
  margin-left: 0.4em;
  display: flex;
`;

interface IProps {
  code: string;
  carat: string;
  color: string;
  clarity: string;
  cutGrade: string;
  flour: string;
  polish: string;
  symmetryAbbreviation: string;
  caratMinimumPrice: number;
  auctionName: string;
  medias: any;
  auctionId: string;
  serialNo: string;
  id: string;
  t?: any;
  auctionSubdomain: string;
  auctionStartDate: string;
  joinedAuctions: any;
  parcelType: string;
  auctionType: string;
  auction: any;
  onParticipate: (auctionId: string, auctionDate: string, productId: string, auctionSubdomain: string, parcelType: string, auctionType: string) => void;
  jewelleryDetails: any;
  items: any;
  remarks: any;
  noOfPieces: any;
  productDescription: any;
  lab: string;
  certificate: string;
  productEndDate: any;
  shape: any;
  annualProductState: any;
  currency: any;
  exchangeRates: any;
}

let caratMinPrice: string = '';
let jewelleryType: string | null = "";
let jewelleryCondition: string | null = "";
let jewelleryDescription: string | null = "";
let seconds: any = "";
let conversionStartingAmount: string = '';
const initialState = {
  mouseX: null,
  mouseY: null,
};

const ProductInSlider = (props: IProps) => {
  const [isParticipate, setIsParticipate] = useState(false);
  const [isAnnualParticipate, setIsAnnualParticipate] = useState(false);
  let auctiondemo: string = "";
  const [mouseState, setMouseState] = React.useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>(initialState);
  const {
    t,
    code,
    parcelType,
    auction,
    auctionType,
    jewelleryDetails,
    auctionStartDate,
    carat,
    color,
    id,
    auctionSubdomain,
    serialNo,
    clarity,
    cutGrade,
    flour,
    polish,
    symmetryAbbreviation,
    caratMinimumPrice,
    auctionName,
    medias,
    auctionId,
    remarks,
    lab,
    certificate,
    productEndDate,
    annualProductState,
    shape,
    currency,
    exchangeRates,
  } = props;


  useEffect(() => {
    for (var i = 0; i < props.joinedAuctions.length; i++) {
      if (props.auctionId === props.joinedAuctions[i].perId) {
        setIsParticipate(true);
        break;
      }
    }
  }, [props.auctionId, props.joinedAuctions]);

  if (auction !== undefined) {
    if (auction.auctionType !== undefined) {
      auctiondemo = `${auctionType}`;
    }
  }

  const onPress = (auctionId: string, auctionStartDate: string, productId: string, auctionSubdomain: string, parcelType: string, auctiondemo: string) => {
    props.onParticipate(auctionId, auctionStartDate, productId, auctionSubdomain, parcelType, auctionType);
  }

  caratMinPrice = new Intl.NumberFormat('us-en').format(caratMinimumPrice);


  if (caratMinimumPrice === null || caratMinPrice === 'NaN' || caratMinPrice === '0') {
    caratMinPrice = '-';
  } else {
    let userCurrencyCode = localStorage.getItem('preferredCurrency');
    let userCurrencySymbol = localStorage.getItem('preferredCurrencySymbol');

    if (userCurrencyCode !== currency['code']) {
      let conversionPriceS = new Intl.NumberFormat('us-en').format(Math.round(caratMinimumPrice * exchangeRates[0]['rate']));
      conversionStartingAmount = `(${userCurrencySymbol}${conversionPriceS})`;
    } else {
      conversionStartingAmount = ""
    }
    caratMinPrice = `${currency['symbol']}${caratMinPrice}`
  }

  const ShowImage = () => {
    for (let i = 0; i < medias.length; i++) {
      let mediaItem = medias[i];
      if (mediaItem.mediaType === 'PHOTO') {
        if (mediaItem.primary === true) {
          medias.splice(i, 1);
          medias.unshift(mediaItem);
          break;
        }
      }
    }
    return (
      <StyledProductImage src={`${medias[0].presignedUrl}`} alt='' />
    )
  }


  if (jewelleryDetails !== undefined && jewelleryDetails !== null) {
    if (jewelleryDetails.jewelleryType !== undefined && jewelleryDetails.jewelleryType !== null) {
      jewelleryType = jewelleryDetails.jewelleryType;
    }

    if (jewelleryDetails.jewelleryCondition !== undefined && jewelleryDetails.jewelleryCondition !== null) {
      jewelleryCondition = jewelleryDetails.jewelleryCondition;
    }

    if (jewelleryDetails.jewelleryDescription !== undefined && jewelleryDetails.jewelleryDescription !== null) {
      jewelleryDescription = jewelleryDetails.jewelleryDescription;
    }
  }

  const getProductDescription = () => {
    const CARAT =
      carat === undefined ? "" : `${carat} ${t("common:preview:ct")}`;
    const COLOR = color === undefined ? "" : color;
    const CLARITY = clarity === undefined ? "" : clarity;
    const CUTGRADE = cutGrade === undefined ? "" : cutGrade;
    const FLOUR = flour === undefined ? "" : flour;
    const POLISH =
      polish === undefined ? "" : `${t("common:preview:pol")}=${polish}`;
    const SYMMETRY_ABBREVATION =
      symmetryAbbreviation === undefined
        ? ""
        : `${t("common:preview:sym")}=${symmetryAbbreviation}`;
    const PRODUCT_SHAPE =
      shape === undefined || shape === null ? "" : shape;
    return `${code} ${CARAT} ${COLOR} ${PRODUCT_SHAPE} ${CLARITY} ${CUTGRADE} ${FLOUR} ${POLISH} ${SYMMETRY_ABBREVATION} `;
  };

  const renderer = ({
    formatted: { days, hours, minutes, seconds },
    completed,
  }: any) => {
    if (completed) {
      if (
        days === "00" &&
        hours === "00" &&
        minutes === "00" &&
        seconds === "00"
      ) {
        return <span>00:00:00:00</span>;
      }
    } else {
      return (
        <span>
          {days}:{hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  const productTimeLeft = (startDate: any) => {
    let date: string | null = "";
    let time: string | null = "";
    let timeDifference: any = "";
    let timezone: any = "";

    if (startDate !== null || startDate !== undefined) {
      let previewTime = moment.utc(startDate);
      if (timezone !== null) {
        timezone = localStorage.getItem("timezoneName");
      }
      if (previewTime !== null || previewTime !== undefined) {
        let result = moment.tz(previewTime, timezone);
        let resultFormatter = result.format();
        if (resultFormatter !== "Invalid date") {
          let splitDateTime = resultFormatter.split("T", 2);
          let splitTime: any = "";
          if (splitDateTime[1].includes("+")) {
            splitTime = splitDateTime[1].split("+", 2);
          } else {
            splitTime = splitDateTime[1].split("-", 2);
          }
          date = splitDateTime[0];
          time = splitTime[0];
          let dateTime = `${date} ${time}`;
          let end: any = moment(dateTime);
          timezone = localStorage.getItem("timezoneName");
          let start: any = moment(
            new Date().toLocaleString("en-US", { timeZone: timezone })
          );
          timeDifference = localStorage.getItem("timeDifference");
          timeDifference = timeDifference / 1000;
          timeDifference = parseInt(timeDifference);
          var timeDiff = end - start;
          seconds = Math.round(timeDiff);
          // eslint-disable-next-line no-lone-blocks
          {
            timeDifference[0] === "-"
              ? (seconds = seconds - timeDifference)
              : (seconds = seconds + timeDifference);
          }
        }
      }
    }
    return (
      <Countdown
        date={Date.now() + seconds}
        className="countdownText"
        renderer={renderer}
        zeroPadTime={2}
      />
    );
  };

  const rightClickEvent = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setMouseState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleClose = () => {
    setMouseState(initialState);
  };

  const renderDescriptionSection = () => {
    if (parcelType === "Jewellery") {
      return (
        <StyledTitleWrapper item={true} xs={12}>
          <Grid container={true} spacing={0} className="height">
            <Grid item={true} xs={12}>
              <div>
                <StyledContJwl>
                  <ProductDescription
                    truncateLength={60}
                    data={props.items}
                    isTendor={false}
                  />
                </StyledContJwl>
              </div>
            </Grid>
            <StyledRemarks item={true} xs={12}>
              <div>
                <StyledRemarkLbl>
                  {t("common:preview:perItemDescription:remarks") + ":"}
                  {remarks !== "" &&
                  remarks !== undefined &&
                  remarks !== null ? (
                    <>
                      <EllipsisText text={remarks} length={25} tail={"..."} />
                    </>
                  ) : (
                    ""
                  )}
                </StyledRemarkLbl>
              </div>
            </StyledRemarks>
          </Grid>
        </StyledTitleWrapper>
      );
    } else {
      return (
        <StyledTitleWrapper item={true} xs={12}>
          <EllipsisText
            text={getProductDescription()}
            length={65}
            tail={"..."}
          />
        </StyledTitleWrapper>
      );
    }
  };

  const renderTimeleftSection = () => {
    const isAnnual = auctionType === "Annual" ? true : false;
    if (isAnnual) {
      return (
        <Grid item={true} xs={3} className="borderRightBlue">
          <StyledWrapper>
            <StyledSerialNumberLabelWrapper>
              {t("common:preview:productsList:serialNo")}
            </StyledSerialNumberLabelWrapper>
            <StyledValueConatiner>{serialNo}</StyledValueConatiner>
          </StyledWrapper>
          <StyledWrapper>
            <StyledSerialNumberLabelWrapper>
              {lab}
            </StyledSerialNumberLabelWrapper>
            <StyledValueConatiner
              className="fontBold"
              style={{ textAlign: "center", fontSize: "0.5em" }}
            >
              {certificate}
            </StyledValueConatiner>
          </StyledWrapper>
          <StyledWrapper>
            <StyledTimeLeftLabelWrapper>
              {annualProductState === "Started" ? `${t("common:newCommanTrans:timeleft")}:` : ""}
            </StyledTimeLeftLabelWrapper>
            <StyledTimerSection>
              {annualProductState === "Started"
                ? productTimeLeft(productEndDate)
                : ""}
            </StyledTimerSection>
          </StyledWrapper>
        </Grid>
      );
    } else {
      return (
        <Grid item={true} xs={3} className="borderRightBlue">
          <StyledWrapper>
            <StyledSerialNumberLabelWrapper>
              {t("common:preview:productsList:serialNo")}
            </StyledSerialNumberLabelWrapper>
            <StyledValueConatiner>{serialNo}</StyledValueConatiner>
          </StyledWrapper>
          <StyledWrapper>
            <StyledSerialNumberLabelWrapper>
              {lab}
            </StyledSerialNumberLabelWrapper>
            <StyledValueConatiner
              className="fontBold"
              style={{ textAlign: "center", fontSize: "0.5em" }}
            >
              {certificate}
            </StyledValueConatiner>
          </StyledWrapper>
        </Grid>
      );
    }
  }

  return (
    <>
      {parcelType === "Jewellery" ? (
        <StyledCardContainer onContextMenu={rightClickEvent}>
          <Grid container={true} spacing={0} className="borderBottomBlue">
            {renderDescriptionSection()}
          </Grid>
          <Grid container={true} spacing={0}>
            <Grid container={true} spacing={0} className="borderBottomBlue">
              <StyledWrapper item={true} xs={12}>
                <StyledLabelWrapper className="secondaryLabel fontBold">
                  {t("common:preview:productsList:startingPrice")}
                </StyledLabelWrapper>
                <StyledBidAmount>
                  {/* <span>&#165;</span> */}
                  <span className="fontBold textWrap">{caratMinPrice} {conversionStartingAmount}</span>
                  <StyledLabelWrapper className="secondaryLabel fontBold"></StyledLabelWrapper>
                </StyledBidAmount>
              </StyledWrapper>
            </Grid>
            <Grid container={true} spacing={0} className="borderBottomBlue">
              {renderTimeleftSection()}
              <StyledProductImageContainer item={true} xs={9}>
                {medias === null ||
                  medias === undefined ||
                  medias[0] === undefined ||
                  medias[0] === null ||
                  medias[0].presignedUrl === null ||
                  (medias.length === 1 && medias[0].mediaType !== "PHOTO") ? (
                  <StyledProductImage src="/images/default-diamond.png"></StyledProductImage>
                ) : (
                  <ShowImage />
                )}
              </StyledProductImageContainer>
            </Grid>
          </Grid>
          <StyledSecondContainer container={true} spacing={0}>
            <StyledAuctionNameWrapper
              item={true}
              xs={8}
              className="borderRightBlue"
            >
              <StyledAuctionName>
                {auctionType === "Annual"
                  ? `${auctionName} (${parcelType})`
                  : `${auctionName}`}
              </StyledAuctionName>
            </StyledAuctionNameWrapper>
            <StyledParticipateButtonWrapper item={true} xs={4}>
              <StyledParticipateButton
                className="fontBold"
                onClick={() =>
                  onPress(
                    auctionId,
                    auctionStartDate,
                    id,
                    auctionSubdomain,
                    parcelType,
                    auctiondemo
                  )
                }
                isParticipate={
                  auctionType !== "Annual"
                    ? isParticipate
                    : isAnnualParticipate
                }
              >
                {t("common:landingPage:list:participate")}
              </StyledParticipateButton>
            </StyledParticipateButtonWrapper>
          </StyledSecondContainer>
        </StyledCardContainer>
      ) : (
        <StyledCardContainer onContextMenu={rightClickEvent}>
          <Grid container={true} spacing={0} className="borderBottomBlue">
            {renderDescriptionSection()}
          </Grid>
          <Grid container={true} spacing={0}>
            <Grid container={true} spacing={0} className="borderBottomBlue">
              <StyledWrapper item={true} xs={12}>
                <StyledLabelWrapper className="secondaryLabel fontBold">
                  {t("common:preview:productsList:startingPrice")}
                </StyledLabelWrapper>
                <StyledBidAmount>
                  {/* <span>&#165;</span> */}
                  <span className="fontBold textWrap">{caratMinPrice}  {conversionStartingAmount}</span>
                  <StyledLabelWrapper className="secondaryLabel fontBold">
                    {" "}
                    {t("common:preview:productsList:pct")}
                  </StyledLabelWrapper>
                </StyledBidAmount>
              </StyledWrapper>
            </Grid>
            <Grid container={true} spacing={0} className="borderBottomBlue">
              {renderTimeleftSection()}
              <StyledProductImageContainer item={true} xs={9}>
                {medias === null ||
                  medias === undefined ||
                  medias[0] === undefined ||
                  medias[0] === null ||
                  medias[0].presignedUrl === null ||
                  (medias.length === 1 && medias[0].mediaType !== "PHOTO") ? (
                  <StyledProductImage src="/images/default-diamond.png"></StyledProductImage>
                ) : (
                  <ShowImage />
                )}
              </StyledProductImageContainer>
            </Grid>
          </Grid>
          <StyledSecondContainer container={true} spacing={0}>
            <StyledAuctionNameWrapper
              item={true}
              xs={8}
              className="borderRightBlue"
            >
              <StyledAuctionName>
                {auctionType === "Annual"
                  ? `${auctionName} (${parcelType})`
                  : `${auctionName}`}
              </StyledAuctionName>
            </StyledAuctionNameWrapper>
            <StyledParticipateButtonWrapper item={true} xs={4}>
              <StyledParticipateButton
                className="fontBold"
                onClick={() =>
                  onPress(
                    auctionId,
                    auctionStartDate,
                    id,
                    auctionSubdomain,
                    parcelType,
                    auctiondemo
                  )
                }
                isParticipate={
                  auctionType !== "Annual"
                    ? isParticipate
                    : isAnnualParticipate
                }
              >
                {t("common:landingPage:list:participate")}
              </StyledParticipateButton>
            </StyledParticipateButtonWrapper>
          </StyledSecondContainer>
        </StyledCardContainer>
      )}
      <Menu
        keepMounted
        open={mouseState.mouseY !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          mouseState.mouseY !== null && mouseState.mouseX !== null
            ? { top: mouseState.mouseY, left: mouseState.mouseX }
            : undefined
        }
      >
        <MenuItem
          onClick={() =>
            onPress(
              auctionId,
              auctionStartDate,
              id,
              auctionSubdomain,
              parcelType,
              auctiondemo
            )
          }
        >
          Details
        </MenuItem>
      </Menu>
    </>
  );
}
export default ProductInSlider;